import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import StyledSelect from "components/StyledSelect";
import useMediaQuery from "components/useMediaQuery";
import FileUploadBox from "components/FileUploadBox";
import LabelWithLines from "components/LabelWithLines";

import {
  AuthenticationData,
  useCreateTypeOptions,
} from "entities/FamilyEdit/sdk";

import { colors } from "theme";
import { URLS } from "config/urls";

import FormHelperText from "components/FormHelperText";
import LoadingScreen from "components/LoadingScreen";
import {
  parseDataFromPdf,
  useDetailDocumentData,
} from "entities/Documents/sdk";
import { reverse } from "utils/urls";

const AuthorizationType = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const typeOptions = useCreateTypeOptions();

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [typeError, setTypeError] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const [documentId, setDocumentId] = useState<number | null>(null);

  const { control, watch } = useForm<AuthenticationData>({
    defaultValues: {
      type: undefined,
    },
  });

  const watchType = watch("type");

  const [file, setFile] = useState<File | null>(null);

  const { data: documentData, mutate } = useDetailDocumentData(
    documentId ?? undefined
  );

  useEffect(() => {
    if (polling && documentData && documentData.is_ready) {
      setLoading(false);
      setPolling(false);
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
          documentId: documentData.id,
          stepname: "primary-contact",
        })
      );
    }
  }, [documentData, polling]);

  useEffect(() => {
    if (polling && documentId) {
      const interval = setInterval(() => {
        mutate();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [polling, documentId, mutate]);

  const handleNextClick = () => {
    let valid = true;
    if (!watchType) {
      setTypeError(t("fieldRequired"));
      valid = false;
    } else {
      setTypeError(null);
    }
    if (watchType === "WAITLIST") {
      if (valid) {
        setIsSubmitting(true);
        navigate(URLS.FAMILY_SPECIALIST_PRIMARY_CONTACT);
      }
    } else if (watchType === "INCOME_ELIGIBLE") {
      if (valid) {
        setIsSubmitting(true);
        navigate(URLS.FAMILY_SPECIALIST_INCOME_ELIGIBLE_PRIMARY_CONTACT);
      }
    } else {
      if (file && watchType) {
        setFileError(null);
        const formData = {
          file: file,
          document_type: watchType,
        };
        setLoading(true);
        parseDataFromPdf(formData)
          .then((data) => {
            setDocumentId(data.data.id);
            setPolling(true);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setFileError(t("fieldRequired"));
      }
    }

    setIsSubmitting(false);
  };

  return (
    <>
      {loading && <LoadingScreen message={t("loadingMessage")} />}
      <Stack alignItems="center">
        <Text variant="titleMedium" sx={{ m: "10px 0" }}>
          {t("aboutYouHeader")}
        </Text>
        <form style={{ width: matches ? "400px" : "100%" }}>
          <LabelWithLines label={t("authorizations")} />
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("type")}
                options={typeOptions}
                fullWidth
                margin="normal"
                error={!!typeError}
                helperText={typeError}
              />
            )}
          />
          {watchType !== "WAITLIST" &&
            watchType !== "INCOME_ELIGIBLE" &&
            watchType && (
              <>
                <LabelWithLines label={t("uploadReferralLetter")} />
                <Stack alignItems="center">
                  <FileUploadBox file={file} setFile={setFile} />
                  {fileError && (
                    <FormHelperText error sx={{ mt: 2 }}>
                      {fileError}
                    </FormHelperText>
                  )}
                </Stack>
              </>
            )}
        </form>
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleNextClick}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AuthorizationType;
