import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import Divider from "components/Divider";
import Chip from "components/Chip";
import IconButton from "components/IconButton";
import Link from "components/Link";
import IncomeSubsection from "components/IncomeSubsection";

import theme, { colors } from "theme";

import { URLS } from "config/urls";

import { useMyFamily } from "entities/Family/sdk";

import useIncomeEligibleFlowNavigation from "../hooks";
import { CheckIcon, EditIcon } from "icons";
import { reverse } from "utils/urls";
import {
  MINIMUM_WAGE,
  WEEKS_PER_MONTH_TIMES,
  HOURS_FOR_CONTRIBUTION,
  getTotalWeeklyHours,
  getTotalMonthly,
} from "utils/calcIncomeTotals";

const VerifyInformationPage = () => {
  const { t } = useTranslation();
  const { data: familyData } = useMyFamily();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const { navigateNext, getBackUrl } = useIncomeEligibleFlowNavigation();

  if (!familyData || !voucherIdParam) {
    return null;
  }

  const showSchoolTrainingSection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) =>
        parentToIncomeEligible.is_attending_school_or_training
    )
  );

  const showWagesSection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) =>
        parentToIncomeEligible.has_wages_from_employment
    )
  );

  const showRentailIncomeSection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) => parentToIncomeEligible.has_rental_income
    )
  );

  const showLottaryEarningsSection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) => parentToIncomeEligible.has_lottery_earnings
    )
  );

  const showAlimonySection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) => parentToIncomeEligible.has_alimony
    )
  );

  const showWorkersCompensationSection = familyData.family_members.some(
    (member) =>
      member.parent_to_income_eligibles.some(
        (parentToIncomeEligible) =>
          parentToIncomeEligible.has_workers_compensation
      )
  );

  const showUnemploymentBenefitsSection = familyData.family_members.some(
    (member) =>
      member.parent_to_income_eligibles.some(
        (parentToIncomeEligible) =>
          parentToIncomeEligible.has_unemployment_benefits
      )
  );

  const showRetirementIncomeSection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) => parentToIncomeEligible.has_retirement_income
    )
  );

  const showSelfEmploymentSection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) =>
        parentToIncomeEligible.has_self_employment_income
    )
  );

  const showSeekingActivitySection = familyData.family_members.some((member) =>
    member.parent_to_income_eligibles.some(
      (parentToIncomeEligible) =>
        parentToIncomeEligible.is_seeking_work_or_education
    )
  );

  const voucherId = Number(voucherIdParam);
  const totalWeeklyHours = getTotalWeeklyHours(
    familyData.family_members,
    voucherId
  );
  const totalMonthly = getTotalMonthly(familyData.family_members, voucherId);

  return (
    <FullPageDialog
      title={t("verifyInformation")}
      backUrl={getBackUrl({ family: familyData })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
          <div style={{ margin: theme.spacing(2, 0) }}>
            <Text
              component="p"
              variant="titleMedium"
              color={colors.onSurface}
              sx={{ mb: theme.spacing(2) }}
            >
              {t("calculatedNetMonthlyMessage")}
            </Text>
            <Text component="p" variant="bodyMedium" color={colors.onSurface}>
              {t("selectPencilToEditMessage")}
            </Text>
          </div>
          {showWagesSection && (
            <>
              <Stack
                spacing={1}
                direction="row"
                sx={{ alignItems: "center", mt: 3, width: "100%" }}
              >
                <Divider sx={{ flex: 1 }} />
                <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                  {t("wages")}
                </Text>
                <Divider sx={{ flex: 1 }} />
              </Stack>
              {familyData.family_members.map((parent) => {
                const parentIncomeEligible =
                  parent.parent_to_income_eligibles.find(
                    (p) => p.voucher.id.toString() === voucherIdParam
                  );
                if (
                  !parentIncomeEligible ||
                  !parentIncomeEligible.has_wages_from_employment
                ) {
                  return null;
                }
                const verifiedDocsCount = familyData.documents.filter(
                  (doc) =>
                    doc.parent?.id === parent.id &&
                    doc.vouchers?.some(
                      (v) => v.id.toString() === voucherIdParam
                    ) &&
                    doc.wage_from_employment_verification === true
                ).length;

                return (
                  <Stack key={parent.id} sx={{ mt: 2 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack>
                        <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                          {parent.first_name} {parent.last_name}
                        </Text>
                        <Stack direction="row" spacing={1}>
                          <Text
                            variant="labelMedium"
                            fontWeight="600"
                            color={colors.onSurfaceVariant}
                          >
                            {parentIncomeEligible?.total_hours_per_week}{" "}
                            {t("hWeek")}
                          </Text>
                          <Text
                            variant="labelMedium"
                            fontWeight="600"
                            color={colors.onSurfaceVariant}
                          >
                            ${parentIncomeEligible?.total_monthly_wages} /{" "}
                            {t("month")}
                          </Text>
                        </Stack>
                      </Stack>
                      <Link
                        to={reverse(URLS.VERIFY_INCOME_EMPLOYMENT, {
                          parentId: parent.id,
                          voucherId: voucherIdParam,
                        })}
                      >
                        <IconButton sx={{ width: "42px", height: "42px" }}>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Stack>

                    {verifiedDocsCount > 0 ? (
                      <div>
                        <Chip
                          icon={<CheckIcon />}
                          label={
                            <Text
                              sx={{
                                fontWeight: "bold",
                                textTransform: "lowercase",
                              }}
                            >
                              {verifiedDocsCount}{" "}
                              {verifiedDocsCount !== 1
                                ? t("documents")
                                : t("document")}{" "}
                              {t("verified")}
                            </Text>
                          }
                          sx={{ backgroundColor: colors.secondaryContainer }}
                        />
                      </div>
                    ) : (
                      <Text variant="labelMedium" color={colors.error}>
                        {t("missingDocuments")}
                      </Text>
                    )}
                  </Stack>
                );
              })}
            </>
          )}
          {showSelfEmploymentSection && (
            <IncomeSubsection
              family={familyData}
              voucherId={voucherIdParam}
              fieldName="has_self_employment_income"
              monthlyValueField="total_monthly_income"
              docVerificationField="self_employment_income_verification"
              title="Self Employment"
              navigateUrl={URLS.VERIFY_INCOME_EMPLOYMENT}
            />
          )}

          {showRentailIncomeSection && (
            <IncomeSubsection
              family={familyData}
              voucherId={voucherIdParam}
              fieldName="has_rental_income"
              monthlyValueField="total_rental_income"
              docVerificationField="rental_income_verification"
              title="Rental Income"
              navigateUrl={URLS.VERIFY_INCOME_PARENT_RENTAL_INCOME}
            />
          )}
          {showLottaryEarningsSection && (
            <IncomeSubsection
              family={familyData}
              voucherId={voucherIdParam}
              fieldName="has_lottery_earnings"
              monthlyValueField="total_lottery_earnings"
              docVerificationField="lottery_earnings_verification"
              title="Lottery Earnings"
              navigateUrl={URLS.VERIFY_INCOME_PARENT_LOTTERY}
            />
          )}
          {showAlimonySection && (
            <IncomeSubsection
              family={familyData}
              voucherId={voucherIdParam}
              fieldName="has_alimony"
              monthlyValueField="total_alimony"
              docVerificationField="alimony_verification"
              title="Alimony"
              navigateUrl={URLS.VERIFY_INCOME_PARENT_ALIMONY}
            />
          )}
          {showWorkersCompensationSection && (
            <IncomeSubsection
              family={familyData}
              voucherId={voucherIdParam}
              fieldName="has_workers_compensation"
              monthlyValueField="total_workers_compensation"
              docVerificationField="workers_compensation_verification"
              title="Workers Compensation"
              navigateUrl={URLS.VERIFY_INCOME_PARENT_WORKERS_COMPENSATION}
            />
          )}
          {showUnemploymentBenefitsSection && (
            <IncomeSubsection
              family={familyData}
              voucherId={voucherIdParam}
              fieldName="has_unemployment_benefits"
              monthlyValueField="total_unemployment_benefits"
              docVerificationField="unemployment_benefits_verification"
              title="Unemployment Benefits"
              navigateUrl={URLS.VERIFY_INCOME_PARENT_UNEMPLOYMENT}
            />
          )}
          {showRetirementIncomeSection && (
            <IncomeSubsection
              family={familyData}
              voucherId={voucherIdParam}
              fieldName="has_retirement_income"
              monthlyValueField="total_retirement_income"
              docVerificationField="retirement_income_verification"
              title="Retirement Income"
              navigateUrl={URLS.VERIFY_INCOME_PARENT_RETIREMENT}
            />
          )}
          {showSchoolTrainingSection && (
            <>
              <Stack
                spacing={1}
                direction="row"
                sx={{ alignItems: "center", mt: 3, width: "100%" }}
              >
                <Divider sx={{ flex: 1 }} />
                <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                  {t("schoolTrainingHours")}
                </Text>
                <Divider sx={{ flex: 1 }} />
              </Stack>
              {familyData.family_members.map((parent) => {
                const parentIncomeEligible =
                  parent.parent_to_income_eligibles.find(
                    (p) => p.voucher.id.toString() === voucherIdParam
                  );
                if (
                  !parentIncomeEligible ||
                  !parentIncomeEligible.is_attending_school_or_training
                ) {
                  return null;
                }
                const monthlyValue =
                  (parentIncomeEligible.total_enrolled_course_credits *
                    HOURS_FOR_CONTRIBUTION ||
                    parentIncomeEligible.total_hours_a_week_in_school_or_training ||
                    0) *
                  WEEKS_PER_MONTH_TIMES *
                  MINIMUM_WAGE;
                const verifiedDocsCount = familyData.documents.filter(
                  (doc) =>
                    doc.parent?.id === parent.id &&
                    doc.vouchers?.some(
                      (v) => v.id.toString() === voucherIdParam
                    ) &&
                    doc.school_or_training_verification === true
                ).length;

                return (
                  <Stack key={parent.id} sx={{ mt: 2 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack>
                        <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                          {parent.first_name} {parent.last_name}
                        </Text>
                        <Stack direction="row" spacing={1}>
                          <Text
                            variant="labelMedium"
                            fontWeight="600"
                            color={colors.onSurfaceVariant}
                          >
                            {parentIncomeEligible.total_enrolled_course_credits *
                              HOURS_FOR_CONTRIBUTION ||
                              parentIncomeEligible.total_hours_a_week_in_school_or_training}{" "}
                            {t("hWeek")}
                          </Text>
                          <Text
                            variant="labelMedium"
                            fontWeight="600"
                            color={colors.onSurfaceVariant}
                          >
                            $
                            {Number.isInteger(monthlyValue)
                              ? monthlyValue
                              : monthlyValue.toFixed(2)}{" "}
                            / {t("month")}
                          </Text>
                        </Stack>
                      </Stack>
                      <Link
                        to={reverse(
                          URLS.VERIFY_INCOME_VERIFICATION_SCHOOL_OR_TRAINING,
                          {
                            parentId: parent.id,
                            voucherId: voucherIdParam,
                          }
                        )}
                      >
                        <IconButton sx={{ width: "42px", height: "42px" }}>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Stack>

                    {verifiedDocsCount > 0 ? (
                      <div>
                        <Chip
                          icon={<CheckIcon />}
                          label={
                            <Text
                              sx={{
                                fontWeight: "bold",
                                textTransform: "lowercase",
                              }}
                            >
                              {verifiedDocsCount}{" "}
                              {verifiedDocsCount !== 1
                                ? t("documents")
                                : t("document")}{" "}
                              {t("verified")}
                            </Text>
                          }
                          sx={{ backgroundColor: colors.secondaryContainer }}
                        />
                      </div>
                    ) : (
                      <Text variant="labelMedium" color={colors.error}>
                        {t("missingDocuments")}
                      </Text>
                    )}
                  </Stack>
                );
              })}
            </>
          )}
          {showSeekingActivitySection && (
            <>
              <Stack
                spacing={1}
                direction="row"
                sx={{ alignItems: "center", mt: 3, width: "100%" }}
              >
                <Divider sx={{ flex: 1 }} />
                <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                  {t("seekingActivity")}
                </Text>
                <Divider sx={{ flex: 1 }} />
              </Stack>
              {familyData.family_members.map((parent) => {
                const parentIncomeEligible =
                  parent.parent_to_income_eligibles.find(
                    (p) => p.voucher.id.toString() === voucherIdParam
                  );
                if (
                  !parentIncomeEligible ||
                  !parentIncomeEligible.is_seeking_work_or_education
                ) {
                  return null;
                }

                const monthlyValue =
                  parentIncomeEligible?.hours_per_day_seeking_work_or_training *
                  WEEKS_PER_MONTH_TIMES *
                  MINIMUM_WAGE;

                return (
                  <Stack key={parent.id} sx={{ mt: 2 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack>
                        <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                          {parent.first_name} {parent.last_name}
                        </Text>
                        <Stack direction="row" spacing={1}>
                          <Text
                            variant="labelMedium"
                            fontWeight="600"
                            color={colors.onSurfaceVariant}
                          >
                            {
                              parentIncomeEligible?.hours_per_day_seeking_work_or_training
                            }{" "}
                            {t("hWeek")}
                          </Text>
                          <Text
                            variant="labelMedium"
                            fontWeight="600"
                            color={colors.onSurfaceVariant}
                          >
                            $
                            {Number.isInteger(monthlyValue)
                              ? monthlyValue
                              : monthlyValue.toFixed(2)}{" "}
                            / {t("month")}
                          </Text>
                        </Stack>
                      </Stack>
                      <Link
                        to={reverse(URLS.VERIFY_INCOME_SEEKING_ACTIVITY, {
                          parentId: parent.id,
                          voucherId: voucherIdParam,
                        })}
                      >
                        <IconButton sx={{ width: "42px", height: "42px" }}>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          )}
          <Stack
            sx={{
              alignItems: "center",
              backgroundColor: colors.surfaceContainer,
              padding: theme.spacing(2),
              margin: theme.spacing(3, 0),
              borderRadius: theme.shape.borderRadius,
            }}
            spacing={0.5}
          >
            <Stack
              spacing={1}
              direction="row"
              sx={{ alignItems: "center", mt: 3, width: "100%" }}
            >
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("total")}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
            <Text variant="bodyMedium" color={colors.onSurfaceVariant}>
              {totalWeeklyHours} {t("hWeek")}
            </Text>
            <Text variant="bodyMedium" color={colors.onSurfaceVariant}>
              $
              {Number.isInteger(totalMonthly)
                ? totalMonthly
                : totalMonthly.toFixed(2)}{" "}
              / {t("month")}
            </Text>
            <Divider sx={{ width: "100%" }} />
          </Stack>
        </Container>

        <div
          style={{
            padding: theme.spacing(2, 3),
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BasicButton
            label={t("submit")}
            onClick={() => navigateNext({ family: familyData })}
          />
        </div>
      </Stack>
    </FullPageDialog>
  );
};
export default VerifyInformationPage;
