import BasicButton from "components/BasicButton";
import Checkbox from "components/Checkbox";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import FileUploadBox from "components/FileUploadBox";
import FormControlLabel from "components/FormControlLabel";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import { CloseIcon } from "icons";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { colors } from "theme";
import StyledSelect from "components/StyledSelect";
import { useFamilyDetail } from "entities/FamilySpecialist/sdk";
import { formatToCamelCase } from "utils/format";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  documentBooleansToLabels,
  uploadDocument,
} from "entities/Documents/sdk";
import FormHelperText from "components/FormHelperText";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  onClose: () => void;
  familyId: number;
  onSubmit?: () => void;
  initialData?: {
    voucher: number;
    documentType: "family" | "child" | "parent" | "placement";
    child?: number;
    parent?: number;
    childFields?: {
      citizenship_verification?: boolean;
      age_verification?: boolean;
      relationship_verification?: boolean;
    };
    parentFields?: {
      id_verification?: boolean;
      military_retirement_verification?: boolean;
      military_active_duty_verification?: boolean;
      school_or_training_verification?: boolean;
      income_verification?: boolean;
      rental_income_verification?: boolean;
      lottery_earnings_verification?: boolean;
      alimony_verification?: boolean;
      workers_compensation_verification?: boolean;
      unemployment_benefits_verification?: boolean;
      retirement_income_verification?: boolean;
      wage_from_employment_verification?: boolean;
      is_income_verified?: boolean;
      is_business_verified?: boolean;
    };
    familyFields?: {
      residency_verification?: boolean;
    };
  };
}

const FamilySpecialistDocumentUpload = ({
  open,
  onClose,
  familyId,
  initialData,
  onSubmit,
}: IProps) => {
  const { data: family } = useFamilyDetail(familyId);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setFile(null);
    reset();
    onClose();
  };

  const {
    control,
    reset,
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
  } = useForm({ defaultValues: initialData as any, shouldUnregister: true });

  const documentType = watch("documentType");
  const authorizationId = watch("voucher");

  const onSave = async (data: any) => {
    if (!file) {
      return setFileError(t("fieldRequired"));
    }

    await uploadDocument({
      file,
      voucherId: data.voucher,
      familyId: familyId,
      parentId: data.parent,
      childId: data.child,
      placementId: data.placement,
      childFields: data.childFields,
      parentFields: data.parentFields,
      familyFields: data.familyFields,
      placementFields: data.placementFields,
    });

    await onSubmit?.();
    handleClose();
    toast.success(t("fileUploadSuccess", { filename: file.name }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: colors.surfaceContainerLow,
        },
      }}
    >
      <DialogTitle>
        Upload Documents
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            pt: "30px",
            borderTop: "1px solid",
            borderColor: colors.outlineVariant,
          }}
        >
          <FileUploadBox file={file} setFile={setFile} />
          {fileError && (
            <FormHelperText error sx={{ mt: 2 }}>
              {fileError}
            </FormHelperText>
          )}
          <Stack
            sx={{
              mx: (theme) => theme.spacing(1),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Controller
              name="voucher"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label="Authorization"
                  options={
                    family?.vouchers.map((voucher) => ({
                      label: `${t(formatToCamelCase(voucher.type))}- ${dayjs(voucher.created_at).format("MM/DD/YYYY")}`,
                      value: voucher.id,
                    })) || []
                  }
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.voucher}
                  helperText={errors.voucher?.message?.toString()}
                />
              )}
            />

            <Controller
              name="documentType"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label="Document Type"
                  options={[
                    { label: "Family Document", value: "family" },
                    { label: "Child Document", value: "child" },
                    { label: "Parent Document", value: "parent" },
                    { label: "Placement Document", value: "placement" },
                  ]}
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.documentTpe}
                  helperText={errors.documentType?.message?.toString()}
                />
              )}
            />

            {documentType === "parent" && (
              <Controller
                name="parent"
                control={control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label="Parent"
                    options={
                      family?.family_members.map((member) => ({
                        label: `${member.first_name} ${member.last_name}`,
                        value: member.id,
                      })) || []
                    }
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.parent}
                    helperText={errors.parent?.message?.toString()}
                  />
                )}
              />
            )}
            {documentType === "child" && (
              <Controller
                name="child"
                control={control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label="Child"
                    options={
                      family?.children.map((child) => ({
                        label: `${child.first_name} ${child.last_name}`,
                        value: child.id,
                      })) || []
                    }
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.child}
                    helperText={errors.child?.message?.toString()}
                  />
                )}
              />
            )}

            {documentType === "placement" && (
              <Controller
                name="placement"
                control={control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label="Placement"
                    options={
                      family?.vouchers
                        .find(
                          (voucher) =>
                            voucher.id.toString() === authorizationId.toString()
                        )
                        ?.placements.map((placement) => ({
                          label: placement.provider_name || "Unknown Name",
                          value: placement.id,
                        })) || []
                    }
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.placement}
                    helperText={errors.placement?.message?.toString()}
                  />
                )}
              />
            )}

            {documentType === "parent" && (
              <>
                <Controller
                  name="parentFields.id_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["id_verification"]}
                    />
                  )}
                />
                <Controller
                  name="parentFields.military_retirement_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "military_retirement_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.military_active_duty_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "military_active_duty_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.school_or_training_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "school_or_training_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.income_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["income_verification"]}
                    />
                  )}
                />
                <Controller
                  name="parentFields.rental_income_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels["rental_income_verification"]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.lottery_earnings_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "lottery_earnings_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.alimony_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["alimony_verification"]}
                    />
                  )}
                />
                <Controller
                  name="parentFields.workers_compensation_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "workers_compensation_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.unemployment_benefits_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "unemployment_benefits_verification"
                        ]
                      }
                    />
                  )}
                />
                <Controller
                  name="parentFields.retirement_income_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "retirement_income_verification"
                        ]
                      }
                    />
                  )}
                />

                <Controller
                  name="parentFields.wage_from_employment_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels[
                          "wage_from_employment_verification"
                        ]
                      }
                    />
                  )}
                />

                <Controller
                  name="parentFields.is_income_verified"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["is_income_verified"]}
                    />
                  )}
                />

                <Controller
                  name="parentFields.is_business_verified"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["is_business_verified"]}
                    />
                  )}
                />
              </>
            )}

            {documentType === "child" && (
              <>
                <Controller
                  name="childFields.citizenship_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels["citizenship_verification"]
                      }
                    />
                  )}
                />
                <Controller
                  name="childFields.age_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={documentBooleansToLabels["age_verification"]}
                    />
                  )}
                />
                <Controller
                  name="childFields.relationship_verification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        documentBooleansToLabels["relationship_verification"]
                      }
                    />
                  )}
                />
              </>
            )}

            {documentType === "family" && (
              <Controller
                name="familyFields.residency_verification"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={documentBooleansToLabels["residency_verification"]}
                  />
                )}
              />
            )}
            {documentType === "placement" && (
              <Controller
                name="placementFields.provider_confirmation"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={documentBooleansToLabels["provider_confirmation"]}
                  />
                )}
              />
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: "20px" }}>
        <BasicButton
          label={"Upload"}
          onClick={handleSubmit(onSave)}
          isDisabled={isSubmitting}
        />
      </DialogActions>
    </Dialog>
  );
};

export default FamilySpecialistDocumentUpload;
