import axios from "axios";
import {
  IEmailContactMethod,
  IPhoneContactMethod,
  ParentData,
  PreferredContactMethod,
} from "entities/FamilyEdit/sdk";
import { useTranslation } from "react-i18next";
import { BASE_URL, getConfig, post, useFetch } from "utils/sdk";

export interface ScheduleType {
  Schedule: string[];
  Trans: string[];
}

export const useDocumentsOptions = () => {
  const { t } = useTranslation();

  const authorizationTypeOptions = [
    { label: t("dtaReferral"), value: "DTA_REFERRAL" },
    { label: t("dcfReferral"), value: "DCF_REFERRAL" },
    { label: t("homelessReferral"), value: "HOMELESS_REFERRAL" },
  ];

  const childCareStatusOptions = [
    {
      label: t("alreadyReceivingChildCareAssistance"),
      value: "ALREADY_RECEIVING_CHILD_CARE_ASSISTANCE",
    },
    {
      label: t("newToChildCareAssistance"),
      value: "NEW_TO_CHILD_CARE_ASSISTANCE",
    },
  ];

  const serviceNeedOptions = [
    { label: t("work"), value: "WORK" },
    { label: t("educationTraining"), value: "EDUCATION_TRAINING" },
    { label: t("other"), value: "OTHER" },
  ];

  const dtaProgramOptions = [
    {
      label: t("initialEngagementSeekingActivity"),
      value: "INITIAL_ENGAGEMENT_SEEKING_ACTIVITY",
    },
    {
      label: t("approvedTAFDCPathwaysToWorkActivity"),
      value: "APPROVED_TAFDC_PATHWAYS_TO_WORK_ACTIVITY",
    },
    {
      label: t("approvedSNAPPathToWorkActivity"),
      value: "APPROVED_SNAP_PATH_TO_WORK_ACTIVITY",
    },
    { label: t("relativeCaregiver"), value: "RELATIVE_CAREGIVER" },
    { label: t("transitionalChildCare"), value: "TRANSITIONAL_CHILD_CARE" },
  ];

  const childCareOptions = [
    { label: t("familyChildCare"), value: "FAMILY_CHILD_CARE" },
    { label: t("notFamilyChildCare"), value: "NOT_FAMILY_CHILD_CARE" },
  ];
  const referralSourceOptions = [
    {
      label: t("letterFromHomelessShelter"),
      value: "LETTER_FROM_HOMELESS_SHELTER",
    },
    {
      label: t("referralFromMassHousingAndCommunityDevelopment"),
      value:
        "REFERRAL_FROM_MASSACHUSETTS_DEPARTMENT_OF_HOUSING_AND_COMMUNITY_DEVELOPMENT",
    },
    { label: t("mcKinneyVentoForm"), value: "MCKINNEY_VENTO" },
  ];
  const childCareNeed = [
    { label: t("fullTime"), value: "FULL_TIME" },
    { label: t("partTime"), value: "PART_TIME" },
  ];
  const transportationNeedsOptions = [
    { label: t("noNeed"), value: "NO_NEED" },
    { label: t("homeToProvider"), value: "HOME_TO_PROVIDER" },
    { label: t("providerToHome"), value: "PROVIDER_TO_HOME" },
    {
      label: t("homeToProviderAndProviderToHome"),
      value: "HOME_TO_PROVIDER_AND_PROVIDER_TO_HOME",
    },
  ];

  const transportOptions = [
    {
      value: "BOTH_WAYS_TO_AND_FROM_PROVIDER",
      label: t("bothWaysToAndFromProvider"),
    },
    {
      value: "ONE_WAY_EITHER_TO_OR_FROM_PROVIDER",
      label: t("oneWayEitherToOrFromProvider"),
    },
    {
      value: "BOTH_WAYS_WHEN_SCHOOL_IS_NOT_IN_SESSION",
      label: t("bothWaysWhenSchoolIsNotInSession"),
    },
    {
      value: "ONE_WAY_WHEY_SCHOOL_IS_NOT_IN_SESSION",
      label: t("oneWayWhenSchoolIsNotInSession"),
    },
    {
      value: "NONE",
      label: t("none"),
    },
  ];

  const placementOptions = [
    { value: "NS", label: t("noService") },
    { value: "FT", label: t("fullTime") },
    { value: "PT", label: t("partTime") },
    { value: "BS", label: t("beforeSchool") },
    { value: "AS", label: t("afterSchool") },
    { value: "BA", label: t("beforeAndAfterSchool") },
  ];

  return {
    authorizationTypeOptions,
    childCareStatusOptions,
    serviceNeedOptions,
    dtaProgramOptions,
    childCareOptions,
    referralSourceOptions,
    transportationNeedsOptions,
    childCareNeed,
    transportOptions,
    placementOptions,
  };
};

interface IParseDataFromPdfResponse {
  id: number;
}

export interface ICreator {
  preferred_contact_method: PreferredContactMethod;
  phone_contact_methods: IPhoneContactMethod[];
  email_contact_methods: IEmailContactMethod[];
  preferred_language: string;
  first_name: string;
  last_name: string;
  id: number;
}

export interface IPrimaryContactCreator extends Omit<ICreator, "id"> {}

interface HomelessData {
  id: number;
  referral_source: string;
  referral_date: string;
  child_care_status: string;
  shelter: string;
  shelter_contact: string;
  shelter_contact_email: string;
  shelter_phone: string;
  ccrr: string;
}

interface DtaData {
  id: number;
  dta_program: string;
  dta_referral_number: string;
  referral_date: string;
  child_care_status: string;
  ccrr: string;
  service_need: string;
  dta_referral_type: string;
  tafdc_closing_date: string;
  issuing_location: string;
}

interface DcfData {
  id: number;
  child_care_status: string;
  referral_date: string;
  dcf_case_number: string;
  dcf_authorization_number: string;
  dcf_region: string;
}

interface FIleData {
  file_type: string;
  file: string;
  file_name: string;
}

export interface IDetailDocumentData {
  id: number;
  file: FIleData;
  document_type: string;
  family_data: {
    id: number;
    street_address: string;
    unit_address: string;
    city: string;
    zip_code: string;
    creator: ICreator;
    child_care_need: string;
    child_care_duration: string;
    preferred_program_type: string;
    child_care_transportation_needs: string;
    provider_name: string;
    provider_program: string;

    parents_data: ParentData[];
    children_data: Array<{
      id: number;
      first_name: string;
      last_name: string;
      gender: "MEN" | "WOMEN";
      preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
      date_of_birth: string;
      relationship: "naturalChild" | "fosterChild" | "guardianChild";
      date_when_child_care_is_needed: string;
      child_has_a_disability: boolean;
      child_is_in_head_start_and_needs_more_hours_of_coverage: boolean;
    }>;
    voucher_data: DtaData &
      DcfData &
      HomelessData & {
        children: Array<{
          id: number;
          first_name: string;
          last_name: string;
        }>;
        placements: Array<{
          id: number;
          child_care_need: "FULL_TIME" | "PART_TIME";
          child_care_duration: string;
          preferred_program_type: "FAMILY_CHILD_CARE" | "NOT_FAMILY_CHILD_CARE";
          child_care_transportation_needs:
            | "NO_NEED"
            | "HOME_TO_PROVIDER"
            | "PROVIDER_TO_HOME"
            | "HOME_TO_PROVIDER_AND_PROVIDER_TO_HOME";
          provider_program: string;
          updated_at: string;
          provider_name: string;
          children: {
            id: number;
            first_name: string;
            last_name: string;
          }[];
          voucher: {
            id: number;
            voucher_type: string;
            created_at: string;
          };
        }>;
      };
  };
  is_ready: boolean;
}

interface IPrimaryContactData {
  creator: IPrimaryContactCreator;
  street_address: string;
  unit_address: string;
  city: string;
  zip_code: string;
}

interface IPlacementData {
  child_care_need: string;
  child_care_duration: string;
  preferred_program_type: string;
  child_care_transportation_needs: string;
  provider_name: string;
  provider_program: string;
  monday: IDailyData;
  tuesday: IDailyData;
  wednesday: IDailyData;
  thursday: IDailyData;
  friday: IDailyData;
  saturday: IDailyData;
  sunday: IDailyData;
}

interface ISpecialistResponse {
  first_name: string;
  last_name: string;
  id: number;
}
interface ISpecialistData {
  specialist_id: number;
}

interface IDailyData {
  schedule: string;
  transportation: string;
}
export interface IWeeklyScheduleData {
  monday: IDailyData;
  tuesday: IDailyData;
  wednesday: IDailyData;
  thursday: IDailyData;
  friday: IDailyData;
  saturday: IDailyData;
  sunday: IDailyData;
}

export type ParsingType =
  | "PARENT_IDENTITY"
  | "PARENT_MILITARY_STATUS"
  | "CHILD_CITIZENSHIP"
  | "CHILD_AGE"
  | "CHILD_RELATIONSHIP"
  | "FAMILY_RESIDENCY"
  | "RENTAL_INCOME"
  | "LOTTERY_EARNINGS"
  | "ALIMONY"
  | "WORKERS_COMPENSATION"
  | "UNEMPLOYMENT_BENEFITS"
  | "RETIREMENT_INCOME"
  | "PARENT_MILITARY_RETIRED"
  | "PARENT_MILITARY_ACTIVE_DUTY"
  | "SCHOOL_OR_TRAINING"
  | "WAGES_FROM_EMPLOYMENT"
  | "INCOME_VERIFICATION"
  | "BUSINESS_VERIFICATION";

export interface IDocument {
  id: number;
  parsing_types: ParsingType[];
  is_ready: boolean;
  id_verification: boolean;
  citizenship_verification: boolean;
  age_verification: boolean;
  relationship_verification: boolean;
  residency_verification: boolean;
  provider_confirmation: boolean;
  military_active_duty_verification: boolean;
  military_retirement_verification: boolean;

  rental_income?: number;
  lottery_earnings?: number;
  alimony?: number;
  workers_compensation?: number;
  unemployment_benefits?: number;
  retirement_income?: number;
  monthly_wages?: number;
  hours_per_week?: number;
  currently_enrolled_course_credits?: number;
  hours_a_week_in_school_or_training?: number;
  total_monthly_income?: number;
  rental_income_verification: boolean;
  lottery_earnings_verification: boolean;
  alimony_verification: boolean;
  workers_compensation_verification: boolean;
  unemployment_benefits_verification: boolean;
  retirement_income_verification: boolean;
  school_or_training_verification: boolean;
  wage_from_employment_verification: boolean;
  is_income_verified: boolean;
  is_business_verified: boolean;

  file: {
    id: number;
    file: string;
    file_type: string;
    original_file_name: string;
  };
  vouchers?: Array<{
    id: number;
  }>;
  parent?: {
    id: number;
  };
  child?: {
    id: number;
  };
  placement?: {
    id: number;
  };
}

export const documentBooleansToLabels = {
  id_verification: "ID Verification",
  military_retirement_verification: "Retirement Verification",
  military_active_duty_verification: "Military Active Duty Verification",
  school_or_training_verification: "School or Training Verification",
  income_verification: "Income Verification",
  citizenship_verification: "Citizenship Verification",
  age_verification: "Age Verification",
  relationship_verification: "Relationship Verification",
  residency_verification: "Residency Verification",
  provider_confirmation: "Provider Confirmation",
  rental_income_verification: "Rental Income Verification",
  lottery_earnings_verification: "Lottery Earnings Verification",
  alimony_verification: "Alimony Income Verification",
  workers_compensation_verification: "Workers’ Compensation Verification",
  unemployment_benefits_verification: "Unemployment Verification",
  retirement_income_verification: "Retirement Verification",
  wage_from_employment_verification: "Wage from Employment Verification",
  is_income_verified: "Self Employment Income Verification",
  is_business_verified: "Self Employment Business Verification",
};

export const parseDataFromPdf = (data: {
  file: File;
  document_type: string;
}) => {
  const postData = new FormData();
  postData.append("file", data.file);
  postData.append("document_type", data.document_type);

  const postParams = getConfig({ isFileUpload: true });
  return axios.post<IParseDataFromPdfResponse>(
    `${BASE_URL}/api/parsed-documents/parse/`,
    postData,
    postParams
  );
};

export const useDetailDocumentData = (document_id?: number) => {
  return useFetch<IDetailDocumentData>(
    document_id ? `/api/parsed-documents/document/${document_id}/` : undefined
  );
};

export const parsedPrimaryContactUpdate = ({
  familyId,
  data,
}: {
  familyId: number;
  data: Partial<IPrimaryContactData>;
}) => {
  return post(
    `${BASE_URL}/api/parsed-documents/parsed-family/${familyId}/update/`,
    data
  );
};

export const createParsedFamily = ({
  parsedFamilyId,
}: {
  parsedFamilyId: number;
}) =>
  post<{ family_id: number }>(
    `${BASE_URL}/api/family-specialists/family/parsed-document/create/`,
    { parsed_family: parsedFamilyId }
  );

export const parsedVoucherUpdate = ({
  parsedVoucherId,
  data,
}: {
  parsedVoucherId: number;
  data: Partial<DtaData & DcfData & HomelessData>;
}) => {
  return post(
    `${BASE_URL}/api/parsed-documents/parsed-voucher/${parsedVoucherId}/update/`,
    data
  );
};

export const DTAReferralUpdate = (
  document_id: number,
  family_id: number,
  data: DtaData
) => {
  return post(
    `${BASE_URL}/api/parsed-documents/parsed-family/${document_id}/${family_id}/authorization/dta-referral/update/`,
    data
  );
};

export const DCFReferralUpdate = (
  document_id: number,
  family_id: number,
  data: DcfData
) => {
  return post(
    `${BASE_URL}/api/parsed-documents/parsed-family/${document_id}/${family_id}/authorization/dcf-referral/update/`,
    data
  );
};

export const homelessReferralUpdate = (
  document_id: number,
  family_id: number,
  data: HomelessData
) => {
  return post(
    `${BASE_URL}/api/parsed-documents/parsed-family/${document_id}/${family_id}/authorization/homeless/update/`,
    data
  );
};

export const parsedPlacementCreate = ({
  voucherId,
  data,
}: {
  voucherId: number;
  data: IPlacementData;
}) =>
  post<{}>(
    `${BASE_URL}/api/parsed-documents/parsed-voucher/${voucherId}/create-parsed-placement/`,
    data
  );

export const parsedPlacementUpdate = ({
  placementId,
  data,
}: {
  placementId: number;
  data: IPlacementData;
}) =>
  post(
    `${BASE_URL}/api/parsed-documents/parsed-placement/${placementId}/update/`,
    data
  );

export const useSpecialistData = () => {
  return useFetch<ISpecialistResponse[]>(
    `/api/family-specialists/family/list-specialists/ `
  );
};

export const specialistAssign = (id: number, data: ISpecialistData) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${id}/specialist/assign/ `,
    data
  );

export const weaklyScheduleTableCreate = (
  id: number,
  data: IWeeklyScheduleData
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${id}/weekly-schedule/create/ `,
    data
  );

export const uploadDocument = ({
  file,
  voucherId,
  familyId,
  parentId,
  childId,
  childFields,
  parentFields,
  familyFields,
  placementId,
  placementFields,
}: {
  file: File;
  voucherId: string;
  familyId: number;
  parentId?: string;
  childId?: string;
  childFields?: Record<string, boolean>;
  parentFields?: Record<string, boolean>;
  familyFields?: Record<string, boolean>;
  placementId?: string;
  placementFields?: Record<string, boolean>;
}) => {
  const postData = new FormData();
  postData.append("file", file);
  postData.append("voucher", voucherId.toString());

  if (parentId) postData.append("parent", parentId.toString());
  if (childId) postData.append("child", childId.toString());
  if (childFields)
    Object.entries(childFields).map(([key, value]) =>
      postData.append(key, value.toString())
    );
  if (parentFields)
    Object.entries(parentFields).map(([key, value]) =>
      postData.append(key, value?.toString())
    );
  if (familyFields)
    Object.entries(familyFields).map(([key, value]) =>
      postData.append(key, value.toString())
    );
  if (placementId) postData.append("placement", placementId.toString());
  if (placementFields)
    Object.entries(placementFields).map(([key, value]) =>
      postData.append(key, value.toString())
    );

  return post(
    `${BASE_URL}/api/family-specialists/family/${familyId}/document/upload/`,
    postData,
    {
      isFileUpload: true,
    }
  );
};

export const uploadAndParseDocument = ({
  file,
  parsingTypes,
  parentId,
  voucherId,
  childId,
  familyId,
  addToAllVouchers = false,
}: {
  file: File;
  parsingTypes: IDocument["parsing_types"];
  parentId?: number;
  voucherId?: number;
  childId?: number;
  familyId?: number;
  addToAllVouchers?: boolean;
}) => {
  const postData = new FormData();
  postData.append("file", file);
  parsingTypes.forEach((parsingType) =>
    postData.append("parsing_types", parsingType)
  );
  postData.append("add_to_all_vouchers", addToAllVouchers.toString());

  if (voucherId) postData.append("voucher", voucherId.toString());
  if (parentId) postData.append("parent", parentId.toString());
  if (childId) postData.append("child", childId.toString());
  if (familyId) postData.append("family", familyId.toString());

  return post(`${BASE_URL}/api/family-members/document/verify/`, postData, {
    isFileUpload: true,
  });
};

export const providerConfirmationUploadDocument = ({
  familyId,
  file,
  voucher,
  placement,
  providerConfirmation,
}: {
  familyId: number;
  file: File;
  voucher: number;
  placement: number;
  providerConfirmation?: boolean;
}) => {
  const postData = new FormData();
  postData.append("file", file);
  postData.append("voucher", voucher.toString());
  postData.append("placement", placement.toString());
  if (providerConfirmation)
    postData.append("provider_confirmation", providerConfirmation.toString());
  return post(
    `${BASE_URL}/api/family-members/family/${familyId}/provider-confirmation/upload-document/`,
    postData,
    {
      isFileUpload: true,
    }
  );
};
