import { forwardRef, useImperativeHandle } from "react";

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import useMediaQuery from "components/useMediaQuery";
import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";
import List, { ListItem } from "components/List";
import TextField from "components/TextField";
import FormHelperText from "components/FormHelperText";

import SuccessfulCreation from "assets/successful-creation.png";

// import { MicIcon } from "icons";
import { colors } from "theme";
import { IChildCareNeeds } from "entities/ChildCareNeeds/sdk";

export interface ChildCareNeedsRef {
  submitForm: () => void;
}

export interface IProps {
  summaryInitial: string;
  onSubmit: (data: IChildCareNeeds) => void;
}

const ChildNeedsForm = forwardRef<ChildCareNeedsRef, IProps>(
  ({ summaryInitial, onSubmit }, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<IChildCareNeeds>({
      defaultValues: {
        child_care_need_summary: summaryInitial,
      },
    });
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:600px)");

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    return (
      <>
        <Box sx={{ alignSelf: "center" }}>
          <Stack alignItems={"center"} sx={{ marginBottom: "30px" }}>
            <img
              src={SuccessfulCreation}
              alt="Successful Creation"
              style={{ width: matches ? "138px" : "105px" }}
            />
            <Text
              variant="headlineMedium"
              sx={{ mt: 2, mb: 1, color: colors.primary, textAlign: "center" }}
            >
              {t("accountCreationSuccessful")}
            </Text>
          </Stack>
          <Stack alignItems={"center"}>
            <Text
              variant="titleLarge"
              sx={{ mt: 2, mb: 1, textAlign: "center" }}
            >
              {t("describeChildCareNeeds")}
            </Text>
            <Text
              variant="titleMedium"
              sx={{ mt: 1, mb: 2, textAlign: "center" }}
            >
              {t("forExample")}
              <List sx={{ listStyleType: "disc", pl: 2 }}>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("exampleQuestion1")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("exampleQuestion2")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("exampleQuestion3")}
                </ListItem>
              </List>
            </Text>
            <Controller
              name="child_care_need_summary"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    minRows={3}
                    variant="outlined"
                    label={t("describeWhatYouNeddHelpWIth")}
                    // InputProps={{
                    //   endAdornment: <MicIcon color="primary" />,
                    // }}
                    sx={{ mb: 0, maxWidth: "420px" }}
                    error={!!errors.child_care_need_summary}
                  />
                  {!!errors.child_care_need_summary && (
                    <FormHelperText error>
                      {errors.child_care_need_summary.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
            {/* <Text
            variant="labelMedium"
            sx={{ pl: "15px", color: colors.onSurfaceVariant }}
          >
            {t("textOrAudio")}
          </Text> */}
          </Stack>
        </Box>
      </>
    );
  }
);

export default ChildNeedsForm;
