import { useParams, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Text from "components/Text";
import Stack from "components/Stack";
import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import BasicButton from "components/BasicButton";
import TextField from "components/TextField";
import Divider from "components/Divider";

import { useMyFamily } from "entities/Family/sdk";
import { URLS } from "config/urls";
import { colors } from "theme";
import { incomeEligibleParentAutohrizationUpdate } from "entities/Authorization/sdk";
import { reverse } from "utils/urls";

interface FormValues {
  monthReported?: number;
  yearReported?: number;
  gross_total_receipts_or_sales_in_month?: number;
  advertising?: number;
  automobile_expenses?: number;
  commissions_and_fees?: number;
  cost_of_goods_sold?: number;
  contact_labour?: number;
  employee_benefit_programs?: number;
  insurance?: number;
  interest_paid_on_mortgage_owed_banks?: number;
  other_interest_payment?: number;
  legal_and_professional_services?: number;
  office_expenses?: number;
  pension_or_profit_sharing_plan?: number;
  repair_and_maintenance?: number;
  rent_for_leased_vehicle_machinery_equipment?: number;
  rent_for_other_business_property?: number;
  supplies?: number;
  taxes_and_licenses?: number;
  transportation?: number;
  utilities?: number;
  wages_and_salaries_for_employees?: number;
  total_expenses?: number;
  net_income?: number;
}

const fieldTranslationKeys: Record<string, string> = {
  advertising: "advertising",
  automobile_expenses: "automobileExpenses",
  commissions_and_fees: "commissionsAndFees",
  cost_of_goods_sold: "costOfGoodsSold",
  contact_labour: "contractLabor",
  employee_benefit_programs: "employeeBenefitPrograms",
  insurance: "insurance",
  interest_paid_on_mortgage_owed_banks: "interestPaidOnMortgageOwedBanks",
  other_interest_payment: "otherInterest",
  legal_and_professional_services: "legalProfessionalServices",
  office_expenses: "officeExpenses",
  pension_or_profit_sharing_plan: "pensionProfitSharing",
  repair_and_maintenance: "repairMaintenance",
  rent_for_leased_vehicle_machinery_equipment: "rentLeasedEquipment",
  rent_for_other_business_property: "rentOtherProperty",
  supplies: "supplies",
  taxes_and_licenses: "taxesLicenses",
  transportation: "transportation",
  utilities: "utilities",
  wages_and_salaries_for_employees: "wagesSalaries",
};

const VerifySelfEmploymentIncomeFormPage = () => {
  const { t } = useTranslation();
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const navigate = useNavigate();
  const { data: family, mutate } = useMyFamily();
  const parentId = parseInt(String(parentIdParam));
  const voucherId = parseInt(String(vouchersIdParam));

  const parent = family?.family_members?.find((p) => p.id === parentId);
  const parentIncomeEligible = parent?.parent_to_income_eligibles.find(
    (e) => e.voucher.id === voucherId
  );

  let defaultMonth: number | undefined;
  let defaultYear: number | undefined;
  if (parentIncomeEligible?.month_year_reported) {
    const date = new Date(parentIncomeEligible.month_year_reported);
    defaultMonth = date.getMonth() + 1;
    defaultYear = date.getFullYear();
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      monthReported: defaultMonth,
      yearReported: defaultYear,
      gross_total_receipts_or_sales_in_month:
        parentIncomeEligible?.gross_total_receipts_or_sales_in_month,
      advertising: parentIncomeEligible?.advertising,
      automobile_expenses: parentIncomeEligible?.automobile_expenses,
      commissions_and_fees: parentIncomeEligible?.commissions_and_fees,
      cost_of_goods_sold: parentIncomeEligible?.cost_of_goods_sold,
      contact_labour: parentIncomeEligible?.contact_labour,
      employee_benefit_programs:
        parentIncomeEligible?.employee_benefit_programs,
      insurance: parentIncomeEligible?.insurance,
      interest_paid_on_mortgage_owed_banks:
        parentIncomeEligible?.interest_paid_on_mortgage_owed_banks,
      other_interest_payment: parentIncomeEligible?.other_interest_payment,
      legal_and_professional_services:
        parentIncomeEligible?.legal_and_professional_services,
      office_expenses: parentIncomeEligible?.office_expenses,
      pension_or_profit_sharing_plan:
        parentIncomeEligible?.pension_or_profit_sharing_plan,
      repair_and_maintenance: parentIncomeEligible?.repair_and_maintenance,
      rent_for_leased_vehicle_machinery_equipment:
        parentIncomeEligible?.rent_for_leased_vehicle_machinery_equipment,
      rent_for_other_business_property:
        parentIncomeEligible?.rent_for_other_business_property,
      supplies: parentIncomeEligible?.supplies,
      taxes_and_licenses: parentIncomeEligible?.taxes_and_licenses,
      transportation: parentIncomeEligible?.transportation,
      utilities: parentIncomeEligible?.utilities,
      wages_and_salaries_for_employees:
        parentIncomeEligible?.wages_and_salaries_for_employees,
      total_expenses: parentIncomeEligible?.total_expenses,
      net_income: parentIncomeEligible?.net_income,
    },
  });

  const onSubmit = async (data: FormValues) => {
    let month_year_reported = undefined;
    if (data.monthReported && data.yearReported) {
      month_year_reported = `${data.yearReported}-${String(
        data.monthReported
      ).padStart(2, "0")}-01`;
    }

    const payload = {
      ...data,
      month_year_reported,
    };

    delete payload.monthReported;
    delete payload.yearReported;

    await incomeEligibleParentAutohrizationUpdate({
      authorizationId: voucherId,
      parentId,
      data: payload,
    });

    const updatedFamily = await mutate();
    if (updatedFamily) {
      navigate(
        reverse(URLS.VERIFY_INCOME_VERIFY_BUSINESS, { voucherId, parentId })
      );
    }
  };

  if (!parentId || !voucherId || !family || !parent) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("verifySelfEmploymentIncomeTitle")}
      backUrl={reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME, {
        voucherId,
        parentId,
      })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack spacing={2} gap={1}>
            <Text variant="titleMedium">
              {t("completeSelfEmploymentIncome", {
                parentName: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
            <Text variant="titleMedium">{t("pleaseCompleteBelow")}</Text>
          </Stack>

          <Stack
            spacing={1}
            direction="row"
            sx={{ alignItems: "center", mt: 3, width: "100%" }}
          >
            <Divider sx={{ flex: 1 }} />
            <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
              {t("earningsForParent", {
                parentName: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
            <Divider sx={{ flex: 1 }} />
          </Stack>

          <Stack direction="row" spacing={2} sx={{ mt: 2, width: "100%" }}>
            <Controller
              name="monthReported"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("monthReported")}
                  fullWidth
                  margin="normal"
                  type="number"
                  error={!!errors.monthReported}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value === "" ? null : Number(value));
                  }}
                />
              )}
            />
            <Controller
              name="yearReported"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("yearReported")}
                  fullWidth
                  margin="normal"
                  type="number"
                  error={!!errors.yearReported}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value === "" ? null : Number(value));
                  }}
                />
              )}
            />
          </Stack>

          <Controller
            name="gross_total_receipts_or_sales_in_month"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("grossTotalReceipts")}
                fullWidth
                margin="normal"
                type="number"
                error={!!errors.gross_total_receipts_or_sales_in_month}
                placeholder={t("includingAllTips")}
                onChange={(e) => {
                  const value = e.target.value;
                  field.onChange(value === "" ? null : Number(value));
                }}
              />
            )}
          />
          <Stack
            spacing={1}
            direction="row"
            sx={{ alignItems: "center", mt: 4, width: "100%" }}
          >
            <Divider sx={{ flex: 1 }} />
            <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
              {t("businessExpensesInMonth")}
            </Text>
            <Divider sx={{ flex: 1 }} />
          </Stack>

          {[
            "advertising",
            "automobile_expenses",
            "commissions_and_fees",
            "cost_of_goods_sold",
            "contact_labour",
            "employee_benefit_programs",
            "insurance",
            "interest_paid_on_mortgage_owed_banks",
            "other_interest_payment",
            "legal_and_professional_services",
            "office_expenses",
            "pension_or_profit_sharing_plan",
            "repair_and_maintenance",
            "rent_for_leased_vehicle_machinery_equipment",
            "rent_for_other_business_property",
            "supplies",
            "taxes_and_licenses",
            "transportation",
            "utilities",
            "wages_and_salaries_for_employees",
          ].map((fieldName) => (
            <Controller
              key={fieldName}
              name={fieldName as keyof FormValues}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(fieldTranslationKeys[fieldName])}
                  fullWidth
                  margin="normal"
                  type="number"
                  error={!!errors[fieldName as keyof FormValues]}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value === "" ? null : Number(value));
                  }}
                />
              )}
            />
          ))}
          <Stack
            spacing={1}
            direction="row"
            sx={{ alignItems: "center", mt: 4, width: "100%" }}
          >
            <Divider sx={{ flex: 1 }} />
            <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
              {t("summary")}
            </Text>
            <Divider sx={{ flex: 1 }} />
          </Stack>

          <Controller
            name="total_expenses"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("totalExpenses")}
                fullWidth
                margin="normal"
                type="number"
                error={!!errors.total_expenses}
                onChange={(e) => {
                  const value = e.target.value;
                  field.onChange(value === "" ? null : Number(value));
                }}
              />
            )}
          />

          <Controller
            name="net_income"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("netIncome")}
                fullWidth
                margin="normal"
                type="number"
                error={!!errors.net_income}
                onChange={(e) => {
                  const value = e.target.value;
                  field.onChange(value === "" ? null : Number(value));
                }}
              />
            )}
          />
        </Container>

        <Stack
          gap={2}
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <BasicButton label={t("next")} onClick={handleSubmit(onSubmit)} />
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default VerifySelfEmploymentIncomeFormPage;
