import { useForm, Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import Checkbox from "components/Checkbox";
import Divider from "components/Divider";
import FormControlLabel from "components/FormControlLabel";
import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";
import TextField from "components/TextField";
import MultiSelect from "components/MultiSelect";
import FormControl from "components/FormControl";
import Radio, { RadioGroup } from "components/Radio";
import Text from "components/Text";
import FormHelperText from "components/FormHelperText";

import DCFReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/DCFReferral";
import HomelessReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/HomelessReferral";
import DTAReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/DTAReferral";
import IncomeEligible from "entities/FamilyEdit/components/AddAuthorizationForm/components/IncomeEligible";

import {
  AuthenticationData,
  useStatusOptions,
  useTypeOptions,
  authorizationCreate,
  authorizationUpdate,
} from "entities/FamilyEdit/sdk";
import {
  IVoucher,
  useHouseholdSizeFamilySpecialist,
} from "entities/FamilySpecialist/sdk";
import { useDocumentsOptions } from "entities/Documents/sdk";
import dayjs from "dayjs";

import { colors } from "theme";

interface IProps {
  onSave: () => void;
  familyId: number;
  initialData?: IVoucher | null;
  refetchFamilyDetail: () => void;
  children: {
    first_name: string;
    last_name: string;
    id?: number;
  }[];
}

const getDefaultValues = (
  initialData: IVoucher | null | undefined,
  referralSourceOptions: { label: string; value: string }[]
): AuthenticationData => {
  const defaultData = {
    ...initialData,
    children: initialData?.children.map((child) => child.id.toString()) || [],
    referral_source:
      initialData?.referral_source || referralSourceOptions[0].value,
    family_has_experienced_domestic_violence:
      initialData?.family_has_experienced_domestic_violence || false,
    family_is_experiencing_homelessness:
      initialData?.family_is_experiencing_homelessness || false,
    parent_has_a_disability: initialData?.parent_has_a_disability || false,
    parent_is_a_member_of_the_us_military:
      initialData?.parent_is_a_member_of_the_us_military || false,
    parent_is_an_early_educator:
      initialData?.parent_is_an_early_educator || false,
    parent_is_receiving_treatment_for_substance_abuse:
      initialData?.parent_is_receiving_treatment_for_substance_abuse || false,
    teen_parent: initialData?.teen_parent || false,
    child_only_needs_coverage_during_school_closures:
      initialData?.child_only_needs_coverage_during_school_closures || false,
    created_at: initialData?.created_at
      ? dayjs(initialData?.created_at).format("MM/DD/YYYY")
      : "",
    household_size: initialData?.household_size,
    total_annual_income: initialData?.total_annual_income,
    annual_income_status: initialData?.annual_income_status || undefined,
    is_issued: initialData?.is_issued || false,
  };

  if (initialData?.referral_date) {
    defaultData.referral_date = dayjs(initialData?.referral_date).format(
      "MM/DD/YYYY"
    );
  }

  if (initialData?.tafdc_closing_date) {
    defaultData.tafdc_closing_date = dayjs(
      initialData?.tafdc_closing_date
    ).format("MM/DD/YYYY");
  }

  return defaultData;
};

export interface AddAuthorizationFormRef {
  submitForm: () => void;
}

const AddAuthorizationForm = forwardRef<AddAuthorizationFormRef, IProps>(
  ({ onSave, familyId, initialData, refetchFamilyDetail, children }, ref) => {
    const { referralSourceOptions } = useDocumentsOptions();

    const { t } = useTranslation();
    const formMethods = useForm<AuthenticationData>({
      defaultValues: getDefaultValues(initialData, referralSourceOptions),
    });
    const typeOptions = useTypeOptions({
      excludeUnknown: initialData === null,
    });
    const statusOptions = useStatusOptions();
    const type = formMethods.watch("type");
    const householdSize = Number(formMethods.watch("household_size"));
    const [validatedSize, setValidatedSize] = useState<null | number>(null);

    const { data: householdData } = useHouseholdSizeFamilySpecialist(
      familyId,
      validatedSize!
    );
    const [annualIncomePerYear, setAnnualIncomePerYear] = useState<
      number | null
    >(householdData?.annual_income_per_year || null);

    useEffect(() => {
      if (householdSize >= 2 && householdSize <= 12) {
        setValidatedSize(householdSize);

        if (householdData?.annual_income_per_year) {
          setAnnualIncomePerYear(householdData.annual_income_per_year);
        } else {
          setAnnualIncomePerYear(null);
        }
      } else {
        setValidatedSize(null);
        formMethods.setValue("household_size", null);
        formMethods.setValue("annual_income_status", undefined);
        formMethods.setValue("total_annual_income", null);
        setAnnualIncomePerYear(null);
      }
    }, [householdSize, householdData]);

    useImperativeHandle(ref, () => ({
      submitForm: formMethods.handleSubmit(onSubmit),
    }));

    const onSubmit = async (data: AuthenticationData) => {
      const requestData = {
        ...data,
        ...(annualIncomePerYear && {
          total_annual_income: annualIncomePerYear,
        }),
      };

      if (data.referral_date) {
        requestData.referral_date = dayjs(data.referral_date).format(
          "YYYY-MM-DD"
        );
      }

      if (data.tafdc_closing_date) {
        requestData.tafdc_closing_date = dayjs(data.tafdc_closing_date).format(
          "YYYY-MM-DD"
        );
      }

      const saveAuthorization = initialData
        ? async () =>
            authorizationUpdate({
              id: familyId,
              authorization_id: initialData.id,
              data: requestData,
            })
        : async () => authorizationCreate({ id: familyId, data: requestData });

      await saveAuthorization();
      await refetchFamilyDetail();
      await onSave();
      // .catch((error) => {
      //   console.error(error);
      // });
    };

    const childrenOptions = children.map((child) => {
      let label = `${child.first_name} ${child.last_name}`;

      if (label === " ") {
        label = `Unknown Name (ID: ${child.id})`;
      }

      return {
        label,
        value: child.id?.toString() as string,
      };
    });

    return (
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Stack spacing={2} sx={{ padding: 2 }}>
            <Controller
              name="type"
              control={formMethods.control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label={t("type")}
                  options={typeOptions}
                  fullWidth
                  margin="normal"
                  error={!!formMethods.formState.errors.type}
                  helperText={formMethods.formState.errors.type?.message}
                  disabled={!!initialData}
                />
              )}
            />

            <Controller
              name="children"
              control={formMethods.control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  label={t("childrenAuthorized")}
                  error={!!formMethods.formState.errors.children}
                  helperText={formMethods.formState.errors.children?.message}
                  options={childrenOptions}
                />
              )}
            />

            {initialData && (
              <Controller
                name="status"
                control={formMethods.control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("status")}
                    options={statusOptions}
                    fullWidth
                    margin="normal"
                    disabled
                  />
                )}
              />
            )}

            {type === "WAITLIST" && (
              <Controller
                name="household_size"
                control={formMethods.control}
                rules={{
                  min: { value: 2, message: t("numberRangeError") },
                  max: { value: 12, message: t("numberRangeError") },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("householdSize")}
                    fullWidth
                    margin="normal"
                    inputProps={{ min: "2", max: "12", step: "1" }}
                    error={!!formMethods.formState.errors.household_size}
                    helperText={
                      formMethods.formState.errors.household_size?.message
                    }
                  />
                )}
              />
            )}
            {type === "WAITLIST" && annualIncomePerYear && (
              <Stack>
                <Text
                  variant="titleMedium"
                  sx={{
                    mt: (theme) => theme.spacing(2),
                    alignSelf: "flex-start",
                  }}
                >
                  {t("totalHouseholdIncome")}
                </Text>
                <FormControl
                  component="fieldset"
                  margin="normal"
                  sx={{ width: "100%" }}
                >
                  <Controller
                    name="annual_income_status"
                    control={formMethods.control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        aria-label="employment status"
                        sx={{ flexDirection: "column" }}
                      >
                        <FormControlLabel
                          value="MORE_THAN"
                          control={<Radio />}
                          label={t("moreThan", {
                            yearAmount:
                              annualIncomePerYear.toLocaleString("en-US"),
                          })}
                          sx={{
                            borderBottom: "1px solid",
                            borderColor: colors.outlineVariant,
                            marginRight: 0,
                            padding: "10px 0",
                          }}
                        />
                        <FormControlLabel
                          value="LESS_THAN"
                          control={<Radio />}
                          label={t("lessThan", {
                            yearAmount:
                              annualIncomePerYear.toLocaleString("en-US"),
                          })}
                          sx={{
                            borderBottom: "1px solid",
                            borderColor: colors.outlineVariant,
                            marginRight: 0,
                            padding: "10px 0",
                          }}
                        />
                        <FormControlLabel
                          value="NOT_WORKING"
                          control={<Radio />}
                          label={t("notWorking")}
                          sx={{
                            borderBottom: "1px solid",
                            borderColor: colors.outlineVariant,
                            marginRight: 0,
                            padding: "10px 0",
                          }}
                        />
                      </RadioGroup>
                    )}
                  />
                  {!!formMethods.formState.errors.annual_income_status && (
                    <FormHelperText error sx={{ fontSize: "13px", mt: "15px" }}>
                      {
                        formMethods.formState.errors.annual_income_status
                          .message
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            )}
            {initialData?.created_at && type === "WAITLIST" && (
              <Controller
                name="created_at"
                control={formMethods.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("createdAt")}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                    error={!!formMethods.formState.errors.created_at}
                    helperText={
                      formMethods.formState.errors.created_at?.message
                    }
                    disabled
                  />
                )}
              />
            )}
            {type === "WAITLIST" && (
              <Controller
                name="is_on_waitlist"
                control={formMethods.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("isOnWaitlist")}
                  />
                )}
              />
            )}
            {type === "WAITLIST" && (
              <>
                <Controller
                  name="family_has_experienced_domestic_violence"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("domesticViolence")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="family_is_experiencing_homelessness"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("homelessness")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_has_a_disability"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("parentDisability")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_is_a_member_of_the_us_military"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("militaryMember")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_is_an_early_educator"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("earlyEducator")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="parent_is_receiving_treatment_for_substance_abuse"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("substanceAbuseTreatment")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="teen_parent"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("teenParent")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
                <Controller
                  name="child_only_needs_coverage_during_school_closures"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("schoolClosureCoverage")}
                    />
                  )}
                />
                <Divider sx={{ my: "10px" }} />
              </>
            )}
            {type === "DTA_REFERRAL" && <DTAReferral />}
            {type === "DCF_REFERRAL" && <DCFReferral />}
            {type === "HOMELESS_REFERRAL" && <HomelessReferral />}
            {type === "INCOME_ELIGIBLE" && initialData && (
              <IncomeEligible familyId={familyId} voucher={initialData} />
            )}
          </Stack>
        </form>
      </FormProvider>
    );
  }
);

export default AddAuthorizationForm;
