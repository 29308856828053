import Checkbox from "components/Checkbox";
import Chip from "components/Chip";
import Text from "components/Text";
import Divider from "components/Divider";
import FormControlLabel from "components/FormControlLabel";
import Stack from "components/Stack";
import TextField from "components/TextField";
import BasicButton from "components/BasicButton";
import { colors } from "theme";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { IParentToIncomeEligibles } from "entities/Family/sdk";
import {
  IFamilyDetail,
  IFamilyMember,
  IVoucher,
} from "entities/FamilySpecialist/sdk";
import Link from "components/Link";
import { incomeEligibleToParentUpdate } from "entities/FamilyEdit/sdk";
import FamilySpecialistDocumentUpload from "entities/Documents/componenets/FamilySpecialistDocumentUpload";
import { useState } from "react";
import { getDocuments } from "./utils";
import _ from "lodash";

interface InitialDocumentState {
  voucher: number;
  documentType: "parent" | "family";
  parent?: number;
  family?: number;
  parentFields?: {
    id_verification?: boolean;
    military_retirement_verification?: boolean;
    military_active_duty_verification?: boolean;
    school_or_training_verification?: boolean;
    income_verification?: boolean;
    rental_income_verification?: boolean;
    lottery_earnings_verification?: boolean;
    alimony_verification?: boolean;
    workers_compensation_verification?: boolean;
    unemployment_benefits_verification?: boolean;
    retirement_income_verification?: boolean;
    wage_from_employment_verification?: boolean;
    is_income_verified?: boolean;
    is_business_verified?: boolean;
  };
  familyFields?: {
    residency_verification?: boolean;
  };
}

interface IProps {
  parentToIncomeEligible?: IParentToIncomeEligibles;
  family: IFamilyDetail;
  parent: IFamilyMember;
  voucher: IVoucher;
  onSubmit: () => void;
  updateFamily: () => Promise<IFamilyDetail | undefined>;
}

const IncomeEligibleDocumentsForm = ({
  parentToIncomeEligible,
  family,
  parent,
  voucher,
  onSubmit,
  updateFamily,
}: IProps) => {
  const isFamilyCreator = parent.id === family.creator.id;

  const {
    documents,
    idDocuments,
    militaryDocuments,
    retirementDocuments,
    schoolOrTrainingDocuments,
    employmentDocuments,
    selfEmploymentDocuments,
    rentalIncomeDocuments,
    lotteryIncomeDocuments,
    alimonyDocuments,
    workersCompensationDocuments,
    unemploymentBenefitsDocuments,
    retirementIncomeDocuments,
    residencyDocuments,
  } = getDocuments({ family, voucher, parent, isFamilyCreator });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      is_in_active_military_duty:
        parentToIncomeEligible?.is_in_active_military_duty ?? false,
      is_military_retired: parentToIncomeEligible?.is_military_retired ?? false,
      is_attending_school_or_training:
        parentToIncomeEligible?.is_attending_school_or_training ?? false,
      total_hours_a_week_in_school_or_training:
        parentToIncomeEligible?.total_hours_a_week_in_school_or_training,
      total_enrolled_course_credits:
        parentToIncomeEligible?.total_enrolled_course_credits,
      has_wages_from_employment:
        parentToIncomeEligible?.has_wages_from_employment ?? false,
      total_monthly_wages: parentToIncomeEligible?.total_monthly_wages,
      total_hours_per_week: parentToIncomeEligible?.total_hours_per_week,
      has_self_employment_income:
        parentToIncomeEligible?.has_self_employment_income ?? false,
      total_monthly_income: parentToIncomeEligible?.total_monthly_income,
      is_seeking_work_or_education:
        parentToIncomeEligible?.is_seeking_work_or_education ?? false,
      hours_per_day_seeking_work_or_training:
        parentToIncomeEligible?.hours_per_day_seeking_work_or_training,
      has_rental_income: parentToIncomeEligible?.has_rental_income ?? false,
      total_rental_income: parentToIncomeEligible?.total_rental_income,
      is_resident_at_rental_property:
        parentToIncomeEligible?.is_resident_at_rental_property ?? false,
      has_lottery_earnings:
        parentToIncomeEligible?.has_lottery_earnings ?? false,
      total_lottery_earnings: parentToIncomeEligible?.total_lottery_earnings,
      has_alimony: parentToIncomeEligible?.has_alimony ?? false,
      total_alimony: parentToIncomeEligible?.total_alimony,
      has_workers_compensation:
        parentToIncomeEligible?.has_workers_compensation ?? false,
      total_workers_compensation:
        parentToIncomeEligible?.total_workers_compensation,
      has_unemployment_benefits:
        parentToIncomeEligible?.has_unemployment_benefits ?? false,
      total_unemployment_benefits:
        parentToIncomeEligible?.total_unemployment_benefits,
      has_retirement_income:
        parentToIncomeEligible?.has_retirement_income ?? false,
      total_retirement_income: parentToIncomeEligible?.total_retirement_income,
      has_assets_over_1m: parentToIncomeEligible?.has_assets_over_1m ?? false,
      documents,
    },
  });

  const onSave = async (data: any) => {
    await incomeEligibleToParentUpdate(voucher.id, parent.id, data);
    await onSubmit();
  };

  const { fields: documentFields, append: appendDocumentField } = useFieldArray(
    {
      control,
      name: "documents",
    }
  );

  const [documentInitialState, setDocumentInitialState] = useState<
    InitialDocumentState | undefined
  >(undefined);

  const handleUploadDocumentsClose = () => {
    setDocumentInitialState(undefined);
  };

  const handleUploadDocumentOpen = (
    args: InitialDocumentState["parentFields"]
  ) => {
    setDocumentInitialState({
      voucher: voucher.id,
      documentType: "parent",
      parent: parent.id,
      parentFields: args,
    });
  };

  const handleUploadFamilyDocumentOpen = (
    args: InitialDocumentState["familyFields"]
  ) => {
    setDocumentInitialState({
      voucher: voucher.id,
      documentType: "family",
      familyFields: args,
    });
  };

  const onFileUpload = async () => {
    const newFamily = await updateFamily();
    if (newFamily) {
      const { documents } = getDocuments({
        family: newFamily,
        voucher,
        parent,
        isFamilyCreator,
      });
      const newFileFields = _.differenceBy(
        documents,
        documentFields,
        "document_id"
      );
      appendDocumentField(newFileFields);
    }
  };

  return (
    <Stack sx={{ height: "100%" }} justifyContent="space-between">
      <Stack>
        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Text variant="titleMedium">ID</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>
          {documentFields.map(
            (field, index) =>
              _.find(idDocuments, { document_id: field.document_id }) && (
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  <Link to={field.file} target="_blank" external>
                    <Chip label={field.original_file_name} />
                  </Link>
                  <Controller
                    name={`documents.${index}.id_verification`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Verified"
                      />
                    )}
                  />
                </Stack>
              )
          )}
          {idDocuments.length == 0 && (
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              <BasicButton
                label="Upload a document"
                backgroundColor={colors.secondaryContainer}
                color={colors.secondary}
                onClick={() =>
                  handleUploadDocumentOpen({ id_verification: true })
                }
              />
            </Stack>
          )}
        </Stack>

        {isFamilyCreator && (
          <Stack spacing={2}>
            <Stack
              direction="row"
              sx={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <Text variant="titleMedium">Residency</Text>
              <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
            </Stack>

            {documentFields.map(
              (field, index) =>
                _.find(residencyDocuments, {
                  document_id: field.document_id,
                }) && (
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Link to={field.file} target="_blank" external>
                      <Chip label={field.original_file_name} />
                    </Link>
                    <Controller
                      name={`documents.${index}.residency_verification`}
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Verified"
                        />
                      )}
                    />
                  </Stack>
                )
            )}
            {residencyDocuments.length == 0 && (
              <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                <BasicButton
                  label="Upload a document"
                  backgroundColor={colors.secondaryContainer}
                  color={colors.secondary}
                  onClick={() =>
                    handleUploadFamilyDocumentOpen({
                      residency_verification: true,
                    })
                  }
                />
              </Stack>
            )}
          </Stack>
        )}

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="is_in_active_military_duty"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Military Active Duty</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>
          {watch("is_in_active_military_duty") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(militaryDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.military_active_duty_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {militaryDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        military_active_duty_verification: true,
                      })
                    }
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="is_military_retired"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Retired</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("is_military_retired") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(retirementDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.military_retirement_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {retirementDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        military_retirement_verification: true,
                      })
                    }
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="is_attending_school_or_training"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">School and Training</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("is_attending_school_or_training") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(schoolOrTrainingDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.school_or_training_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {schoolOrTrainingDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        school_or_training_verification: true,
                      })
                    }
                  />
                </Stack>
              )}
              <Controller
                name="total_enrolled_course_credits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Total Course Credits"
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                )}
              />
              <Controller
                name="total_hours_a_week_in_school_or_training"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Total Hours a week in school"
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_wages_from_employment"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Employment</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_wages_from_employment") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(employmentDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.wage_from_employment_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {employmentDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        wage_from_employment_verification: true,
                      })
                    }
                  />
                </Stack>
              )}
              <Controller
                name="total_monthly_wages"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Total Monthly Wages / Earnings"
                    fullWidth
                    type="number"
                  />
                )}
              />
              <Controller
                name="total_hours_per_week"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Working Hours"
                    fullWidth
                    type="number"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_self_employment_income"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Verify Self- Employment Income</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_self_employment_income") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(selfEmploymentDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.is_income_verified`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Income Verified"
                          />
                        )}
                      />
                      <Controller
                        name={`documents.${index}.is_business_verified`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Business Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {selfEmploymentDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        is_income_verified: true,
                        is_business_verified: true,
                      })
                    }
                  />
                </Stack>
              )}
              <Controller
                name="total_monthly_income"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Total Monthly Wages / Earnings"
                    fullWidth
                    type="number"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="is_seeking_work_or_education"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Seeking Activity</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("is_seeking_work_or_education") && (
            <Controller
              name="hours_per_day_seeking_work_or_training"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Hours per week"
                  fullWidth
                  type="number"
                />
              )}
            />
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_rental_income"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Rental Income</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_rental_income") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(rentalIncomeDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.rental_income_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {rentalIncomeDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        rental_income_verification: true,
                      })
                    }
                  />
                </Stack>
              )}

              <Controller
                name="total_rental_income"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Net Monthly Rental Income"
                    fullWidth
                    type="number"
                  />
                )}
              />
              <Controller
                name="is_resident_at_rental_property"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="I reside at the rental property"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_lottery_earnings"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Lottery Earnings</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_lottery_earnings") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(lotteryIncomeDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.lottery_earnings_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {lotteryIncomeDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        lottery_earnings_verification: true,
                      })
                    }
                  />
                </Stack>
              )}

              <Controller
                name="total_lottery_earnings"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Monthly Lottery Earnings"
                    fullWidth
                    type="number"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_alimony"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Alimony</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_alimony") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(alimonyDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.alimony_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {alimonyDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({ alimony_verification: true })
                    }
                  />
                </Stack>
              )}

              <Controller
                name="total_alimony"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Monthly Alimony Income"
                    fullWidth
                    type="number"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_workers_compensation"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Workers’ Compensation</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_workers_compensation") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(workersCompensationDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.workers_compensation_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {workersCompensationDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        workers_compensation_verification: true,
                      })
                    }
                  />
                </Stack>
              )}

              <Controller
                name="total_workers_compensation"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Monthly Worker’s Comp Income"
                    fullWidth
                    type="number"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_unemployment_benefits"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Unemployment</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_unemployment_benefits") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(unemploymentBenefitsDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.unemployment_benefits_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {unemploymentBenefitsDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        unemployment_benefits_verification: true,
                      })
                    }
                  />
                </Stack>
              )}

              <Controller
                name="total_unemployment_benefits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Monthly Unemployment Benefits"
                    fullWidth
                    type="number"
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_retirement_income"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Retirement</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {watch("has_retirement_income") && (
            <>
              {documentFields.map(
                (field, index) =>
                  _.find(retirementIncomeDocuments, {
                    document_id: field.document_id,
                  }) && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Link to={field.file} target="_blank" external>
                        <Chip label={field.original_file_name} />
                      </Link>
                      <Controller
                        name={`documents.${index}.retirement_income_verification`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox {...field} checked={field.value} />
                            }
                            label="Verified"
                          />
                        )}
                      />
                    </Stack>
                  )
              )}
              {retirementIncomeDocuments.length == 0 && (
                <Stack direction="row" sx={{ justifyContent: "center" }} pb={2}>
                  <BasicButton
                    label="Upload a document"
                    backgroundColor={colors.secondaryContainer}
                    color={colors.secondary}
                    onClick={() =>
                      handleUploadDocumentOpen({
                        retirement_income_verification: true,
                      })
                    }
                  />
                </Stack>
              )}

              <Controller
                name="total_retirement_income"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label="Net Monthly Rental Income"
                    fullWidth
                  />
                )}
              />
            </>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Controller
              name="has_assets_over_1m"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label=""
                />
              )}
            />
            <Text variant="titleMedium">Over 1m in assets</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={{ borderTop: "1px solid", borderColor: colors.outline }}>
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
            mb: "20px",
          }}
        >
          <BasicButton
            label="Save"
            onClick={handleSubmit(onSave)}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>

      {Boolean(documentInitialState) && (
        <FamilySpecialistDocumentUpload
          open={Boolean(documentInitialState)}
          onClose={handleUploadDocumentsClose}
          familyId={family.id}
          initialData={documentInitialState}
          onSubmit={onFileUpload}
        />
      )}
    </Stack>
  );
};

export default IncomeEligibleDocumentsForm;
