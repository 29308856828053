import { useLocation, useNavigate, useParams } from "react-router-dom";

import { URLS } from "config/urls";
import { IFamily } from "entities/Family/sdk";
import { reverse } from "utils/urls";

const useIncomeEligibleFlowNavigation = (): {
  getBackUrl: ({ family }: { family: IFamily }) => string;
  getNextUrl: ({ family }: { family: IFamily }) => string;
  navigateBack: ({ family }: { family: IFamily }) => void;
  navigateNext: ({ family }: { family: IFamily }) => void;
} => {
  const location = useLocation();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const voucherId = voucherIdParam ? parseInt(voucherIdParam) : undefined;

  if (!voucherId) {
    throw new Error("Missing voucherId");
  }

  const generateIncomeVerificationSteps = (
    family: IFamily,
    parentId: number
  ) => {
    const incomeEligible = family?.family_members
      .find((fm) => fm.id === parentId)
      ?.parent_to_income_eligibles.find((e) => e.voucher.id === voucherId);

    const steps = [];

    if (incomeEligible?.is_attending_school_or_training) {
      steps.push(
        reverse(URLS.VERIFY_INCOME_VERIFICATION_SCHOOL_OR_TRAINING, {
          voucherId,
          parentId,
        })
      );
    }

    if (incomeEligible?.has_wages_from_employment) {
      steps.push(
        reverse(URLS.VERIFY_INCOME_EMPLOYMENT, { voucherId, parentId })
      );
    }

    if (incomeEligible?.is_seeking_work_or_education) {
      steps.push(
        reverse(URLS.VERIFY_INCOME_SEEKING_ACTIVITY, { voucherId, parentId })
      );
    }

    if (incomeEligible?.has_self_employment_income) {
      steps.push(
        reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME, {
          voucherId,
          parentId,
        })
      );
      if (
        location.pathname !==
        reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_FORM, {
          voucherId,
          parentId,
        })
      ) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_UPLOAD, {
            voucherId,
            parentId,
          })
        );
      }
      if (
        location.pathname !==
        reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_UPLOAD, {
          voucherId,
          parentId,
        })
      ) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_FORM, {
            voucherId,
            parentId,
          })
        );

        steps.push(
          reverse(URLS.VERIFY_INCOME_VERIFY_BUSINESS, {
            voucherId,
            parentId,
          })
        );
      }
    }

    if (incomeEligible?.has_other_income) {
      steps.push(
        reverse(URLS.VERIFY_INCOME_PARENT_OTHER_INCOME, {
          voucherId,
          parentId,
        })
      );

      if (incomeEligible?.has_rental_income) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_PARENT_RENTAL_INCOME, {
            voucherId,
            parentId,
          })
        );
      }

      if (incomeEligible?.has_lottery_earnings) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_PARENT_LOTTERY, {
            voucherId,
            parentId,
          })
        );
      }

      if (incomeEligible?.has_alimony) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_PARENT_ALIMONY, {
            voucherId,
            parentId,
          })
        );
      }

      if (incomeEligible?.has_workers_compensation) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_PARENT_WORKERS_COMPENSATION, {
            voucherId,
            parentId,
          })
        );
      }

      if (incomeEligible?.has_unemployment_benefits) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_PARENT_UNEMPLOYMENT, {
            voucherId,
            parentId,
          })
        );
      }

      if (incomeEligible?.has_retirement_income) {
        steps.push(
          reverse(URLS.VERIFY_INCOME_PARENT_RETIREMENT, {
            voucherId,
            parentId,
          })
        );
      }
    }

    return steps;
  };

  const getFlow = (family: IFamily) => [
    reverse(URLS.VERIFY_INCOME_PRIMARY_CONTACT, { voucherId }),
    reverse(URLS.VERIFY_INCOME_CHILD_CARE_NEEDS, { voucherId }),
    reverse(URLS.VERIFY_INCOME_OVERVIEW, { voucherId }),
    reverse(URLS.VERIFY_INCOME_PARENTS_LIST, { voucherId }),
    reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, { voucherId }),
    reverse(URLS.VERIFY_INCOME_DEPENDENTS_LIST, { voucherId }),
    ...(family?.family_members.map((fm) =>
      reverse(URLS.VERIFY_INCOME_VERIFICATION_FOR_PARENT, {
        voucherId,
        parentId: fm.id,
      })
    ) || []),
    ...(family?.children
      .map((c) => [
        reverse(URLS.VERIFY_INCOME_CITIZENSHIP_FOR_CHILD, {
          voucherId,
          childId: c.id,
        }),
        reverse(URLS.VERIFY_INCOME_RELATIONSHIP_FOR_CHILD, {
          voucherId,
          childId: c.id,
        }),
        reverse(URLS.VERIFY_INCOME_AGE_FOR_CHILD, {
          voucherId,
          childId: c.id,
        }),
      ])
      .flat() || []),
    reverse(URLS.VERIFY_INCOME_RESIDENCY_VERIFICATION, { voucherId }),
    reverse(URLS.VERIFY_INCOME_MILITARY_OR_RETIRED, { voucherId }),
    ...(family?.family_members
      .filter((fm) =>
        fm.parent_to_income_eligibles.find(
          (e) => e.voucher.id === voucherId && e?.is_in_active_military_duty
        )
      )
      .map((fm) =>
        reverse(URLS.VERIFY_INCOME_VERIFICATION_MILITARY, {
          voucherId,
          parentId: fm.id,
        })
      ) || []),

    ...(family?.family_members
      .filter((fm) =>
        fm.parent_to_income_eligibles.find(
          (e) => e.voucher.id === voucherId && e?.is_military_retired
        )
      )
      .map((fm) =>
        reverse(URLS.VERIFY_INCOME_VERIFICATION_RETIREMENT, {
          voucherId,
          parentId: fm.id,
        })
      ) || []),
    ...(family?.family_members.flatMap((fm) => [
      reverse(URLS.VERIFY_PARENT_ELIGIBILITY, { voucherId, parentId: fm.id }),
      ...generateIncomeVerificationSteps(family, fm.id),
    ]) || []),
    reverse(URLS.VERIFY_INCOME_VERIFY_INFORMATION, { voucherId }),
    reverse(URLS.VERIFY_INCOME_PLACEMENT_LIST, { voucherId }),
    ...(family?.vouchers
      .find((v) => v.id === voucherId)
      ?.placements.map((p) =>
        reverse(URLS.VERIFY_INCOME_PROVIDER_CONFIRMATION, {
          voucherId,
          placementId: p.id,
        })
      ) || []),
    reverse(URLS.VERIFY_INCOME_SUCCESS, {
      voucherId,
    }),
  ];

  const getBackUrl = ({ family }: { family: IFamily }) => {
    const flow = getFlow(family);
    const currentStepIndex = flow.findIndex((f) => f === location.pathname);
    const backUrl = flow[currentStepIndex - 1];
    return backUrl;
  };

  const getNextUrl = ({ family }: { family: IFamily }) => {
    const flow = getFlow(family);
    const currentStepIndex = flow.findIndex((f) => f === location.pathname);
    return flow[currentStepIndex + 1];
  };

  const navigateBack = ({ family }: { family: IFamily }) => {
    const backUrl = getBackUrl({ family });
    return navigate(backUrl);
  };

  const navigateNext = ({ family }: { family: IFamily }) => {
    const nextUrl = getNextUrl({ family });
    return navigate(nextUrl);
  };

  return {
    navigateBack,
    navigateNext,
    getBackUrl,
    getNextUrl,
  };
};

export default useIncomeEligibleFlowNavigation;
