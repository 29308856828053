import { useNavigate } from "react-router-dom";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";

import PrimaryContactForm from "entities/FamilySpecialist/PrimaryContactForm";
import { IPrimaryContactData } from "entities/FamilyEdit/sdk";
import { incomeEligibleCreate } from "entities/FamilySpecialist/sdk";

const IncomeEligiblePrimaryContact = () => {
  const navigate = useNavigate();

  const handleSave = (data: Partial<IPrimaryContactData>) => {
    return incomeEligibleCreate(data as IPrimaryContactData).then(
      (response) => {
        navigate(
          reverse(URLS.FAMILY_SPECIALIST_PARENTS, {
            familyId: response.id,
          }),
          {
            state: { isIncomeEligible: true },
          }
        );
      }
    );
  };

  return <PrimaryContactForm onSave={handleSave} />;
};

export default IncomeEligiblePrimaryContact;
