import { IFamilyMember } from "entities/FamilySpecialist/sdk";

export const MINIMUM_WAGE = 15;
export const WEEKS_PER_MONTH_TIMES = 4.3;
export const HOURS_FOR_CONTRIBUTION = 2.5;

export function getTotalWeeklyHours(
  familyMembers: IFamilyMember[],
  voucherId: number
): number {
  return familyMembers.reduce((sum, member) => {
    const parentIncomeEligible = member.parent_to_income_eligibles.find(
      (p) => p.voucher.id === voucherId
    );
    if (!parentIncomeEligible) return sum;

    return (
      sum +
      (parentIncomeEligible.total_hours_per_week || 0) +
      (parentIncomeEligible.total_enrolled_course_credits *
        HOURS_FOR_CONTRIBUTION ||
        parentIncomeEligible.total_hours_a_week_in_school_or_training ||
        0) +
      (parentIncomeEligible.hours_per_day_seeking_work_or_training || 0)
    );
  }, 0);
}

export function getTotalMonthly(
  familyMembers: IFamilyMember[],
  voucherId: number
): number {
  return familyMembers.reduce((sum, member) => {
    const parentIncomeEligible = member.parent_to_income_eligibles.find(
      (p) => p.voucher.id === voucherId
    );
    if (!parentIncomeEligible) return sum;

    let monthlySum = 0;

    if (parentIncomeEligible.has_wages_from_employment) {
      monthlySum += parentIncomeEligible.total_monthly_wages || 0;
    }
    if (parentIncomeEligible.has_self_employment_income) {
      monthlySum += parentIncomeEligible.total_monthly_income || 0;
    }
    if (parentIncomeEligible.has_rental_income) {
      monthlySum += parentIncomeEligible.total_rental_income || 0;
    }
    if (parentIncomeEligible.has_lottery_earnings) {
      monthlySum += parentIncomeEligible.total_lottery_earnings || 0;
    }
    if (parentIncomeEligible.has_alimony) {
      monthlySum += parentIncomeEligible.total_alimony || 0;
    }
    if (parentIncomeEligible.has_workers_compensation) {
      monthlySum += parentIncomeEligible.total_workers_compensation || 0;
    }
    if (parentIncomeEligible.has_unemployment_benefits) {
      monthlySum += parentIncomeEligible.total_unemployment_benefits || 0;
    }
    if (parentIncomeEligible.has_retirement_income) {
      monthlySum += parentIncomeEligible.total_retirement_income || 0;
    }

    if (parentIncomeEligible.is_attending_school_or_training) {
      const schoolHours =
        parentIncomeEligible.total_enrolled_course_credits *
          HOURS_FOR_CONTRIBUTION ||
        parentIncomeEligible.total_hours_a_week_in_school_or_training ||
        0;

      monthlySum += schoolHours * WEEKS_PER_MONTH_TIMES * MINIMUM_WAGE;
    }

    if (parentIncomeEligible.is_seeking_work_or_education) {
      const seekingHours =
        parentIncomeEligible.hours_per_day_seeking_work_or_training || 0;
      monthlySum += seekingHours * WEEKS_PER_MONTH_TIMES * MINIMUM_WAGE;
    }

    return sum + monthlySum;
  }, 0);
}
