import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Control, Controller, FieldErrors } from "react-hook-form";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import FullPageDialog from "components/FullPageDialog";
import TextField from "components/TextField";
import ParseFile from "components/ParseFile";

import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import useIncomeEligibleFlowNavigation from "../hooks";

const SchoolOrTrainingForm = ({
  control,
  errors,
}: {
  control: Control<any, any>;
  errors: FieldErrors<any>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name="total_enrolled_course_credits"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label={t("currentlyEnrolledCredits")}
            fullWidth
            InputLabelProps={{
              shrink:
                field.value !== "" &&
                field.value !== null &&
                field.value !== undefined,
            }}
            margin="normal"
            error={!!errors.total_enrolled_course_credits}
          />
        )}
      />
      <Controller
        name="total_hours_a_week_in_school_or_training"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label={t("hoursPerWeek")}
            fullWidth
            InputLabelProps={{
              shrink:
                field.value !== "" &&
                field.value !== null &&
                field.value !== undefined,
            }}
            margin="normal"
            error={!!errors.total_hours_a_week_in_school_or_training}
          />
        )}
      />
    </>
  );
};

const SchoolOrTrainingPage = () => {
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const parentId = parseInt(String(parentIdParam));
  const { t } = useTranslation();
  const { getBackUrl } = useIncomeEligibleFlowNavigation();

  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );
  const voucherId = vouchersIdParam ? parseInt(vouchersIdParam) : undefined;

  if (!parent || !family || !voucherId) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("schoolOrTrainingTitle")}
      backUrl={getBackUrl({ family })}
      closeUrl={URLS.HOME}
    >
      <ParseFile
        parentId={parentId}
        voucherId={voucherId}
        family={family}
        documentParsingTypes={["SCHOOL_OR_TRAINING"]}
        getDefaultValues={(parentIncomeEligible) => ({
          total_enrolled_course_credits:
            parentIncomeEligible?.total_enrolled_course_credits,
          total_hours_a_week_in_school_or_training:
            parentIncomeEligible?.total_hours_a_week_in_school_or_training,
        })}
        fieldsMapping={{
          currently_enrolled_course_credits: "total_enrolled_course_credits",
          hours_a_week_in_school_or_training:
            "total_hours_a_week_in_school_or_training",
        }}
        FormComponent={SchoolOrTrainingForm}
        reloadFamily={reloadFamily}
        verificationFilter={(document) =>
          document.school_or_training_verification
        }
        header={
          <>
            <Text variant="bodyLarge" color={colors.onSurface}>
              {t("schoolOrTrainingDescription", {
                parentName: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
            <Text variant="titleMedium" color={colors.onSurface}>
              {t("commonExamplesTitle")}
            </Text>
            <Text variant="titleMedium" color={colors.onSurfaceVariant}>
              <ul>
                <li>{t("collegeExample")}</li>
                <li>{t("hsExample")}</li>
                <li>{t("gedExample")}</li>
                <li>{t("vocationalExample")}</li>
              </ul>
            </Text>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadFor")} {parent.first_name} {parent.last_name}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
          </>
        }
      />
    </FullPageDialog>
  );
};

export default SchoolOrTrainingPage;
