import { useParams, useNavigate, useLocation } from "react-router-dom";

import ParentForm from "entities/Parent/components/Form";

import { parentCreateAsSpecialist, IParent } from "entities/Parent/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const ParentAddAsSpecialistPage = () => {
  const navigate = useNavigate();

  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = parseInt(String(familyIdParam));
  const location = useLocation();
  const isIncomeEligible = location.state?.isIncomeEligible;

  const handleSave = (values: Partial<IParent>) =>
    parentCreateAsSpecialist({ familyId, data: values }).then(() => {
      navigate(reverse(URLS.FAMILY_SPECIALIST_PARENTS, { familyId }), {
        state: { isIncomeEligible },
      });
    });

  return <ParentForm onSave={handleSave} />;
};

export default ParentAddAsSpecialistPage;
