import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Box from "components/Box";
import Chip from "components/Chip";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import Container from "components/Container";
import BasicButton from "components/BasicButton";
import FullPageDialog from "components/FullPageDialog";
import FileUploadBox from "components/FileUploadBox";
import { CircularProgress } from "components/Loading";
import useMediaQuery from "components/useMediaQuery";

import { uploadAndParseDocument } from "entities/Documents/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import { CheckIcon, AttentionIcon } from "icons";
import useIncomeEligibleFlowNavigation from "../hooks";

const VerifyRetirementPage = () => {
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const parentId = parseInt(String(parentIdParam));
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const { getBackUrl, getNextUrl } = useIncomeEligibleFlowNavigation();

  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );
  const voucherId = vouchersIdParam ? parseInt(vouchersIdParam) : undefined;

  const parentDocuments =
    family?.documents.filter(
      (document) =>
        document.parent?.id === parentId &&
        document.vouchers?.some((voucher) => voucher.id === voucherId)
    ) || [];

  const uploadedParentDocuments =
    parentDocuments?.filter((document) =>
      document.parsing_types.includes("PARENT_MILITARY_RETIRED")
    ) || [];

  const allUploadedParentDocumentsAreParsed = uploadedParentDocuments.every(
    (document) => document.is_ready
  );

  const retirementConfirmedDocuments = parentDocuments.filter(
    (document) => document.military_retirement_verification
  );
  const retirementConfirmed = retirementConfirmedDocuments.length !== 0;

  useEffect(() => {
    if (!allUploadedParentDocumentsAreParsed) {
      const interval = setInterval(
        () =>
          reloadFamily().then(() => {
            if (allUploadedParentDocumentsAreParsed) {
              clearInterval(interval);
              reloadFamily();
            }
          }),
        2000
      );
      return () => clearInterval(interval);
    }
  }, [allUploadedParentDocumentsAreParsed, reloadFamily]);

  if (!parent || !family || !voucherId) {
    return null;
  }

  const handleFileSelect = (file: any) => {
    setIsUploading(true);
    uploadAndParseDocument({
      parentId,
      file,
      voucherId,
      parsingTypes: ["PARENT_MILITARY_RETIRED"],
    }).then(() => {
      setIsUploading(false);
      reloadFamily();
    });
  };

  return (
    <FullPageDialog
      title={t("verifyRetirementTitle")}
      backUrl={getBackUrl({ family })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack spacing={2}>
            <Text variant="bodyLarge" color={colors.onSurface}>
              {t("needToVerifyInfo")}
            </Text>
            <Text variant="titleMedium" color={colors.onSurfaceVariant}>
              <ul>
                <li>{t("verifyRetirementRequirement")}</li>
              </ul>
            </Text>
            <Text variant="titleMedium">
              {t("verifyRetirementInstructions")}
            </Text>
            <Text variant="titleMedium">{t("verifyRetirementExamples")}</Text>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadFor")} {parent.first_name} {parent.last_name}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>

            {isUploading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
              </div>
            )}
            {!allUploadedParentDocumentsAreParsed &&
              !retirementConfirmed &&
              !isUploading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress
                    thickness={8}
                    size={48}
                    sx={{ color: "#DA2C52" }}
                  />
                  <Text variant="labelLarge" sx={{ marginTop: "16px" }}>
                    {t("verifyingDocuments")}
                  </Text>
                </div>
              )}
            {!retirementConfirmed &&
              allUploadedParentDocumentsAreParsed &&
              parentDocuments.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <AttentionIcon />
                  </div>
                  <Text color="error">{t("couldNotVerifyRetired")}</Text>
                </div>
              )}

            {!retirementConfirmed && (
              <FileUploadBox file={null} setFile={handleFileSelect} />
            )}
            {_.uniqBy(
              [...uploadedParentDocuments, ...retirementConfirmedDocuments],
              "id"
            ).map((document, index) => (
              <Box
                key={index}
                sx={{
                  padding: (theme) => theme.spacing(2, 0),
                  borderBottom: "1px solid #CFC8C3",
                }}
              >
                {document.file.original_file_name}
              </Box>
            ))}
            {retirementConfirmed && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Chip
                  icon={<CheckIcon />}
                  label={
                    <Text sx={{ fontWeight: "bold" }}>
                      {t("retirementStatusVerified")}
                    </Text>
                  }
                  sx={{ backgroundColor: colors.tertiaryContainer }}
                />
              </div>
            )}
          </Stack>
        </Container>
        <Stack
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <Link
            to={getNextUrl({ family })}
            style={{ width: matches ? "auto" : "100%" }}
          >
            <BasicButton label={t("next")} />
          </Link>
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default VerifyRetirementPage;
