import Box from "components/Box";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import EditPanel from "entities/FamilyEdit/components";
import Text from "components/Text";
import {
  IFamilyDetail,
  IFamilyMember,
  IVoucher,
} from "entities/FamilySpecialist/sdk";
import { useState } from "react";
import { EditIcon } from "icons";
import IncomeEligibleDocumentsForm from "../IncomeEligibleDocumentsForm";
import { colors } from "theme";

interface IFamilyMemberProps {
  familyMember: IFamilyMember;
  voucher: IVoucher;
  family: IFamilyDetail;
  updateFamily: () => Promise<IFamilyDetail| undefined>;
}

const FamilyMemberBox = ({
  familyMember,
  voucher,
  family,
  updateFamily,
}: IFamilyMemberProps) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const parentToIncomeEligible = familyMember.parent_to_income_eligibles.find(
    (p) => p.voucher.id === voucher.id
  );

  const documents = family.documents.filter(
    (d) =>
      d.vouchers.find((v) => v.id === voucher.id) && d.parent?.id === familyMember.id
  );
 

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: colors.outline,
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Stack gap="15px">
        <Text variant="titleMedium">
          {familyMember.first_name} {familyMember.last_name}
        </Text>
        <Stack direction="row" gap="30px">
          <Text variant="titleMedium">{documents.length} Documents</Text>
        </Stack>
      </Stack>
      <IconButton
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          height: "40px",
          width: "40px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsEditOpen(true);
        }}
      >
        <EditIcon />
      </IconButton>
      <EditPanel
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        title={`${familyMember.first_name} ${familyMember.last_name} documents`}
      >
        <IncomeEligibleDocumentsForm
          family={family}
          parentToIncomeEligible={parentToIncomeEligible}
          parent={familyMember}
          voucher={voucher}
          updateFamily={updateFamily}
          onSubmit={async () => {
            await updateFamily()
            setIsEditOpen(false);
          }}
        />
      </EditPanel>
    </Box>
  );
};

export default FamilyMemberBox;
