import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import AddButton from "components/AddButton";
import useMediaQuery from "components/useMediaQuery";
import FormHelperText from "components/FormHelperText";

import { URLS } from "config/urls";
import { colors } from "theme";
import { reverse } from "utils/urls";

import { useFamilyChildren, childData } from "entities/FamilyMember/sdk";
import { useFamilySpecialistChildren } from "entities/FamilyEdit/sdk";

const Children = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");

  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const family_id = familyIdParam ? parseInt(familyIdParam) : undefined;
  const location = useLocation();
  const isIncomeEligible = location.state?.isIncomeEligible;

  const { data: fetchedChildrenData, error } = family_id
    ? useFamilySpecialistChildren(family_id)
    : useFamilyChildren();

  const [currentChildrenData, setCurrentChildrenData] = useState<childData[]>(
    []
  );
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (fetchedChildrenData) {
      const preselectedChildren = fetchedChildrenData.map((child) => {
        const today = new Date();
        const birthDate = child.date_of_birth
          ? new Date(child.date_of_birth)
          : null;
        let age = birthDate ? today.getFullYear() - birthDate.getFullYear() : 0;
        const m = birthDate ? today.getMonth() - birthDate.getMonth() : 0;

        if (
          birthDate &&
          (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
        ) {
          age--;
        }

        return {
          firstName: child.first_name,
          lastName: child.last_name,
          age: age,
        };
      });
      setCurrentChildrenData(preselectedChildren);
    }
  }, [fetchedChildrenData]);

  const handleNextClick = () => {
    if (currentChildrenData.length === 0 && !family_id) {
      setShowError(true);
    } else {
      setShowError(false);
      if (family_id) {
        if (isIncomeEligible) {
          navigate(
            reverse(URLS.FAMILY_SPECIALIST_ADDITIONAL_INFORMATION, {
              familyId: family_id,
            })
          );
        } else {
          navigate(
            reverse(URLS.FAMILY_SPECIALIST_SPECIAL_CIRCUMSTANCES, {
              familyId: family_id,
            })
          );
        }
      } else {
        // only for family member flow
        navigate(URLS.FAMILY_INFORMATION_INCOME_VERIFICATION);
      }
    }
  };

  const handleAddButtonClick = () => {
    if (family_id) {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_ADD_CHILD, { familyId: family_id }),
        {
          state: { isIncomeEligible },
        }
      );
    } else {
      navigate(URLS.FAMILY_INFORMATION_ADD_CHILD);
    }
  };

  return (
    <>
      <Stack
        sx={{ mt: "30px", width: matches ? "50%" : "auto" }}
        alignSelf="center"
        alignItems="center"
      >
        <Text variant="titleMedium" sx={{ mt: 2, mb: 2 }}>
          {t("childrenHeader")}
        </Text>
        {error && <Text color="error">{t("errorLoadingData")}</Text>}
        <Stack
          sx={{
            mb: "20px",
            borderBottom: "1px solid",
            borderColor: colors.outline,
            width: "100%",
          }}
        >
          {currentChildrenData.map((child, index) => (
            <Stack
              key={index}
              sx={{
                py: 2,
                borderTop: "1px solid",
                borderColor: colors.outline,
              }}
              direction="row"
              justifyContent="space-between"
            >
              <Text variant="titleMedium">
                {child.firstName} {child.lastName}
              </Text>
              <Text variant="titleMedium">{child.age} yrs</Text>
            </Stack>
          ))}
        </Stack>
        <AddButton
          label={t("addChild")}
          backgroundColor={colors.secondaryContainer}
          onClick={handleAddButtonClick}
        />
        {showError && (
          <FormHelperText error sx={{ mt: "20px" }}>
            {t("youMustAddAtLeastOneChild")}
          </FormHelperText>
        )}
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNextClick} />
        </Stack>
      </Stack>
    </>
  );
};

export default Children;
