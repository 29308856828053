import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import Divider from "components/Divider";

import theme, { colors } from "theme";

import { URLS } from "config/urls";

import { useMyFamily } from "entities/Family/sdk";

import { UploadWhiteIcon } from "icons";
import useIncomeEligibleFlowNavigation from "../hooks";
import { reverse } from "utils/urls";

const VerifySelfEmploymentIncomePage = () => {
  const { t } = useTranslation();
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const { data: family } = useMyFamily();
  const parentId = parseInt(String(parentIdParam));
  const voucherId = parseInt(String(vouchersIdParam));
  const navigate = useNavigate();

  const { getBackUrl } = useIncomeEligibleFlowNavigation();

  const parent = family?.family_members?.find(
    (parent) => parent.id === parentId
  );

  if (!parentId || !voucherId || !family || !parent) {
    return null;
  }

  const handleCompleteForm = () => {
    navigate(
      reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_FORM, {
        voucherId,
        parentId,
      })
    );
  };

  const handleUploadDocuments = () => {
    navigate(
      reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_UPLOAD, {
        voucherId,
        parentId,
      })
    );
  };

  return (
    <FullPageDialog
      title={t("verifySelfEmploymentIncomeTitle")}
      backUrl={getBackUrl({ family })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
          <div style={{ margin: theme.spacing(2, 0) }}>
            <Text
              component="p"
              variant="titleMedium"
              color={colors.onSurface}
              sx={{ mb: theme.spacing(2) }}
            >
              {t("twoWaysMessage", {
                parentName: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
            <Text component="p" variant="labelLarge" color={colors.onSurface}>
              {t("completeFormHeading")}
            </Text>
          </div>
          <Stack gap={theme.spacing(2)}>
            <BasicButton
              label={t("completeFormButton")}
              backgroundColor={colors.secondaryContainer}
              color={colors.onSecondaryContainer}
              onHoverBackground={colors.onHoverSecondaryContainer}
              sx={{ alignSelf: "center" }}
              onClick={handleCompleteForm}
            />
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("orDivider")}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
            <Text component="p" variant="labelLarge" color={colors.onSurface}>
              {t("uploadDocumentsHeading")}
            </Text>
            <BasicButton
              startIcon={<UploadWhiteIcon />}
              label={t("uploadDocumentsButton")}
              sx={{ alignSelf: "center" }}
              onClick={handleUploadDocuments}
            />
          </Stack>
        </Container>
      </Stack>
    </FullPageDialog>
  );
};
export default VerifySelfEmploymentIncomePage;
