import { useNavigate } from "react-router-dom";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";

import PrimaryContactForm from "entities/FamilySpecialist/PrimaryContactForm";
import { waitlistCreate, IPrimaryContactData } from "entities/FamilyEdit/sdk";

const WaitlistPrimaryContact = () => {
  const navigate = useNavigate();

  const handleSave = (data: Partial<IPrimaryContactData>) => {
    return waitlistCreate(data as IPrimaryContactData).then((response) => {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_YOUR_STATUS, { familyId: response.id })
      );
    });
  };

  return <PrimaryContactForm onSave={handleSave} />;
};

export default WaitlistPrimaryContact;
