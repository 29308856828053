import BasicButton from "components/BasicButton";
import Checkbox from "components/Checkbox";
import Chip from "components/Chip";
import Divider from "components/Divider";
import FormControlLabel from "components/FormControlLabel";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import FamilySpecialistDocumentUpload from "entities/Documents/componenets/FamilySpecialistDocumentUpload";
import { documentsMassUpdate } from "entities/FamilyEdit/sdk";
import { IFamilyDetail, IVoucher } from "entities/FamilySpecialist/sdk";
import _ from "lodash";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { colors } from "theme/palette";
import { getDocuments } from "./utils";

interface InitialDocumentState {
  voucher: number;
  documentType: "child";
  child: number;
  childFields: {
    citizenship_verification?: boolean;
    age_verification?: boolean;
    relationship_verification?: boolean;
  };
}

interface IProps {
  family: IFamilyDetail;
  voucher: IVoucher;
  childId: number;
  onSubmit: () => void;
  updateFamily: () => Promise<IFamilyDetail | undefined>;
}
const ChildIncomeEligibleDocumentsForm = ({
  family,
  voucher,
  childId,
  onSubmit,
  updateFamily,
}: IProps) => {
  const {
    documents,
    citizenshipDocuments,
    ageDocuments,
    relationshipDocuments,
  } = getDocuments({ family, voucherId: voucher.id, childId });

  const [documentInitialState, setDocumentInitialState] = useState<
    InitialDocumentState | undefined
  >(undefined);

  const handleUploadDocumentsClose = () => {
    setDocumentInitialState(undefined);
  };

  const handleUploadDocumentOpen = (args: {
    citizenship_verification?: boolean;
    age_verification?: boolean;
    relationship_verification?: boolean;
  }) => {
    setDocumentInitialState({
      voucher: voucher.id,
      documentType: "child",
      child: childId,
      childFields: args,
    });
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { documents },
  });

  const onSave = async (data: any) => {
    const payload = Object.values(data).flat();
    await documentsMassUpdate({ documents: payload });
    await onSubmit();
  };

  const { fields: documentFields, append: appendDocumentField } = useFieldArray(
    {
      control,
      name: "documents",
    }
  );

  const onFileUpload = async () => {
    const newFamily = await updateFamily();
    if (newFamily) {
      const { documents } = getDocuments({
        family: newFamily,
        voucherId: voucher.id,
        childId,
      });
      const newFileFields = _.differenceBy(
        documents,
        documentFields,
        "document_id"
      );
      appendDocumentField(newFileFields);
    }
  };

  return (
    <Stack sx={{ height: "100%" }} justifyContent="space-between">
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Text variant="titleMedium">Citizenship</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>
          {documentFields.map(
            (field, index) =>
              _.find(citizenshipDocuments, {
                document_id: field.document_id,
              }) && (
                <Stack
                  direction="row"
                  sx={{ justifyContent: "space-between" }}
                  key={field.id}
                >
                  <Link to={field.file} target="_blank" external>
                    <Chip label={field.original_file_name} />
                  </Link>
                  <Controller
                    name={`documents.${index}.citizenship_verification`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Verified"
                      />
                    )}
                  />
                </Stack>
              )
          )}
          {citizenshipDocuments.length == 0 && (
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              <BasicButton
                label="Upload a document"
                backgroundColor={colors.secondaryContainer}
                color={colors.secondary}
                onClick={() =>
                  handleUploadDocumentOpen({ citizenship_verification: true })
                }
              />
            </Stack>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Text variant="titleMedium">Age</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>
          {documentFields.map(
            (field, index) =>
              _.find(ageDocuments, {
                document_id: field.document_id,
              }) && (
                <Stack
                  direction="row"
                  sx={{ justifyContent: "space-between" }}
                  key={field.id}
                >
                  <Link to={field.file} target="_blank" external>
                    <Chip label={field.original_file_name} />
                  </Link>
                  <Controller
                    name={`documents.${index}.age_verification`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Verified"
                      />
                    )}
                  />
                </Stack>
              )
          )}

          {ageDocuments.length === 0 && (
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              <BasicButton
                label="Upload a document"
                backgroundColor={colors.secondaryContainer}
                color={colors.secondary}
                onClick={() =>
                  handleUploadDocumentOpen({ age_verification: true })
                }
              />
            </Stack>
          )}
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Text variant="titleMedium">Relationship</Text>
            <Divider sx={{ display: "block", flexGrow: 1, marginLeft: 3 }} />
          </Stack>

          {documentFields.map(
            (field, index) =>
              _.find(relationshipDocuments, {
                document_id: field.document_id,
              }) && (
                <Stack
                  direction="row"
                  sx={{ justifyContent: "space-between" }}
                  key={field.id}
                >
                  <Link to={field.file} target="_blank" external>
                    <Chip label={field.original_file_name} />
                  </Link>
                  <Controller
                    name={`documents.${index}.relationship_verification`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Verified"
                      />
                    )}
                  />
                </Stack>
              )
          )}

          {relationshipDocuments.length === 0 && (
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              <BasicButton
                label="Upload a document"
                backgroundColor={colors.secondaryContainer}
                color={colors.secondary}
                onClick={() =>
                  handleUploadDocumentOpen({ relationship_verification: true })
                }
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack>
        <Stack sx={{ borderTop: "1px solid", borderColor: colors.outline }}>
          <Stack
            gap="20px"
            alignItems="center"
            sx={{
              textAlign: "right",
              mt: "30px",
              mb: "20px",
            }}
          >
            <BasicButton
              label="Save"
              onClick={handleSubmit(onSave)}
              isDisabled={isSubmitting}
            />
          </Stack>
        </Stack>
      </Stack>

      {Boolean(documentInitialState) && (
        <FamilySpecialistDocumentUpload
          open={Boolean(documentInitialState)}
          onClose={handleUploadDocumentsClose}
          familyId={family.id}
          initialData={documentInitialState}
          onSubmit={onFileUpload}
        />
      )}
    </Stack>
  );
};

export default ChildIncomeEligibleDocumentsForm;
