import Button from "components/Button";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";

import { colors } from "theme";

interface ButtonProps {
  backgroundColor?: string;
  color?: string;
  onHoverBackground?: string;
  label: string;
  width?: string;
  height?: string;
  buttonType?: "button" | "submit" | "reset";
  isDisabled?: boolean;
  mobileFontSize?: string;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement>) => void)
    | (() => void);
  sx?: object;
  [key: string]: any;
}

const BasicButton = ({
  backgroundColor,
  color,
  onHoverBackground,
  label,
  width,
  height,
  buttonType,
  isDisabled,
  mobileFontSize,
  onClick,
  sx,
  ...props
}: ButtonProps) => {
  const matches = useMediaQuery("(min-width:600px)");

  const labelDefaultWidth = matches ? "320px" : "100%";
  const btnWidth = width || labelDefaultWidth;
  return (
    <Button
      type={buttonType || "button"}
      variant="contained"
      disableRipple
      disabled={isDisabled}
      onClick={onClick}
      sx={{
        backgroundColor: backgroundColor ? backgroundColor : colors.primary,
        height: height ? height : "52px",
        width: btnWidth,
        borderRadius: "50px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: onHoverBackground
            ? onHoverBackground
            : backgroundColor
              ? backgroundColor
              : colors.onHoverPrimaryContainer,
          "& .text": {
            color: color ? color : colors.white,
          },
        },
        "&.Mui-disabled": {
          backgroundColor: colors.surfaceContainer,
        },
        ...sx,
      }}
      {...props}
    >
      <Text
        className="text"
        variant="labelLarge"
        sx={{
          color: isDisabled ? colors.onSurface : color ? color : colors.white,
          opacity: isDisabled ? 0.5 : 1,
          textTransform: "none",
          fontSize: mobileFontSize && !matches ? mobileFontSize : "14px",
        }}
      >
        {label}
      </Text>
    </Button>
  );
};

export default BasicButton;
