import FullPageDialog from "components/FullPageDialog";

import { URLS } from "config/urls";
import { useTranslation } from "react-i18next";
import { useMyFamily } from "entities/Family/sdk";
import { useParams } from "react-router-dom";
import VerifyEligibilityForm from "entities/Authorization/components/VerifyEligibilityForm";
import { incomeEligibleSetEligibleVerification } from "entities/Authorization/sdk";
import useIncomeEligibleFlowNavigation from "../hooks";

const VerifyEligibilityPage = () => {
  const { t } = useTranslation();

  const { data: familyData, mutate } = useMyFamily();
  const { voucherId: vouchersIdParam, parentId: parentIdParam } = useParams<{
    voucherId: string;
    parentId: string;
  }>();
  const authorizationId = vouchersIdParam
    ? parseInt(vouchersIdParam)
    : undefined;
  const parentId = parentIdParam ? parseInt(parentIdParam) : undefined;

  const { getBackUrl, navigateNext } = useIncomeEligibleFlowNavigation();

  const parent = familyData?.family_members.find(
    (member) => member.id === parentId
  );

  const parentIncomeEligible = parent?.parent_to_income_eligibles.find(
    (e) => e.voucher.id === authorizationId
  );
  const defaultValues = {
    has_wages_from_employment:
      parentIncomeEligible?.has_wages_from_employment || false,
    has_self_employment_income:
      parentIncomeEligible?.has_self_employment_income || false,
    is_seeking_work_or_education:
      parentIncomeEligible?.is_seeking_work_or_education || false,
    is_attending_school_or_training:
      parentIncomeEligible?.is_attending_school_or_training || false,
    has_other_income: parentIncomeEligible?.has_other_income || false,
    has_assets_over_1m: parentIncomeEligible?.has_assets_over_1m || false,
  };

  if (!familyData || !authorizationId || !parent || !parentId) {
    return null;
  }

  const onSave = async (data: typeof defaultValues) => {
    await incomeEligibleSetEligibleVerification({
      authorizationId,
      parentId,
      data,
    });
    const family = await mutate();
    if (family) {
      navigateNext({ family });
    }
  };

  return (
    <FullPageDialog
      title={t("verifyEligibility")}
      backUrl={getBackUrl({ family: familyData })}
      closeUrl={URLS.HOME}
    >
      <VerifyEligibilityForm
        defaultValues={defaultValues}
        onSave={onSave}
        parent={parent}
      />
    </FullPageDialog>
  );
};

export default VerifyEligibilityPage;
