import DateInput from "components/DateInput";
import StyledSelect from "components/StyledSelect";
import Stack from "components/Stack";
import Divider from "components/Divider";
import Text from "components/Text";

import { useDocumentsOptions } from "entities/Documents/sdk";
import { IVoucher, useFamilyDetail } from "entities/FamilySpecialist/sdk";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFormWarnings } from "utils/forms";
import ChildBox from "./ChildBox";
import FamilyMemberBox from "./FamilyMemberBox";

import { getTotalMonthly, getTotalWeeklyHours } from "utils/calcIncomeTotals";

import theme, { colors } from "theme";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

interface IProps {
  familyId: number;
  voucher: IVoucher;
}

const IncomeEligible = ({ familyId, voucher }: IProps) => {
  const { data: family, mutate } = useFamilyDetail(familyId);
  const { t } = useTranslation();

  const { control } = useFormContext();
  const { fieldWarningsAndErrors } = useFormWarnings({
    formState: control._formState,
    fieldsWithWarnings: [],
  });
  const { childCareStatusOptions } = useDocumentsOptions();

  if (!family || !mutate) {
    return null;
  }

  const totalWeeklyHours = getTotalWeeklyHours(
    family.family_members,
    voucher.id
  );
  const totalMonthly = getTotalMonthly(family.family_members, voucher.id);

  return (
    <>
      <Controller
        name="referral_date"
        control={control}
        render={({ field }) => (
          <DateInput
            {...field}
            label={t("referralDate")}
            control={control}
            required={false}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="child_care_status"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("childCareStatus")}
            options={childCareStatusOptions}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />

      <Controller
        name="is_issued"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label="Is Issued"
          />
        )}
      />

      {family?.family_members.map((member) => (
        <FamilyMemberBox
          familyMember={member}
          voucher={voucher}
          family={family}
          updateFamily={mutate}
        />
      ))}
      {voucher.children.map((child) => (
        <ChildBox
          child={child}
          voucher={voucher}
          family={family}
          updateFamily={mutate}
        />
      ))}

      <Stack
        sx={{
          alignItems: "center",
          backgroundColor: colors.surfaceContainer,
          padding: theme.spacing(2),
          margin: theme.spacing(3, 0),
          borderRadius: theme.shape.borderRadius,
        }}
        spacing={0.5}
      >
        <Stack
          spacing={1}
          direction="row"
          sx={{ alignItems: "center", mt: 3, width: "100%" }}
        >
          <Divider sx={{ flex: 1 }} />
          <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
            {t("total")}
          </Text>
          <Divider sx={{ flex: 1 }} />
        </Stack>
        <Text variant="bodyMedium" color={colors.onSurfaceVariant}>
          {totalWeeklyHours} {t("hWeek")}
        </Text>
        <Text variant="bodyMedium" color={colors.onSurfaceVariant}>
          $
          {Number.isInteger(totalMonthly)
            ? totalMonthly
            : totalMonthly.toFixed(2)}{" "}
          / {t("month")}
        </Text>
        <Divider sx={{ width: "100%" }} />
      </Stack>
    </>
  );
};

export default IncomeEligible;
