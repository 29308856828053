import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";

import PlacementForm from "entities/Placement/components/Form";

import {
  placementUpdateInUserFamily,
  IPlacement,
} from "entities/Placement/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const PlacementEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { placementId: placementIdParam, voucherId: voucherIdParam } =
    useParams<{
      placementId: string;
      voucherId: string;
    }>();
  const placementId = parseInt(String(placementIdParam));
  const { data: family } = useMyFamily();

  const placements = family?.vouchers.flatMap((voucher) => voucher.placements);
  const placement = placements?.find(
    (placement) => placement.id === placementId
  );

  const missingFieldsForPlacement = family?.missing_fields
    .flatMap((voucher) => voucher.missing_fields.placement_missing_fields)
    .filter(
      (placementMissingFields) =>
        placementMissingFields.id === placement?.id &&
        placementMissingFields.missing_fields.length > 0
    )
    .flatMap((placementMissingFields) => placementMissingFields.missing_fields);

  if (!placement || !voucherIdParam) {
    return null;
  }

  const handleSave = (data: Partial<IPlacement>) => {
    return placementUpdateInUserFamily({ placementId, data }).then(() => {
      navigate(
        reverse(URLS.VERIFY_INCOME_PLACEMENT_LIST, {
          voucherId: voucherIdParam,
        })
      );
    });
  };

  const placementWithoutNullFields = _.reduce(
    placement,
    (prev, value, key) => {
      if (value !== null) return { ...prev, [key]: value };
      return prev;
    },
    {}
  );

  return (
    <FullPageDialog
      title={t("placements")}
      backUrl={reverse(URLS.VERIFY_INCOME_PLACEMENT_LIST, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ paddingTop: 2 }}>
        <PlacementForm
          initialValues={placementWithoutNullFields}
          fieldsWithWarnings={missingFieldsForPlacement}
          onCancel={() => {
            navigate(
              reverse(URLS.VERIFY_INCOME_PLACEMENT_LIST, {
                voucherId: voucherIdParam,
              })
            );
          }}
          onSave={handleSave}
          isMissingFields={true}
        />
      </Container>
    </FullPageDialog>
  );
};

export default PlacementEditPage;
