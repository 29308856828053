import _ from "lodash";
import { useTranslation } from "react-i18next";

import { post, BASE_URL } from "utils/sdk";
import { IFamily } from "entities/Family/sdk";

export enum AuthorizationStatus {
  "INCOMPLETE_SIGN_UP" = "INCOMPLETE_SIGN_UP",
  "INCOMPLETE_PROFILE" = "INCOMPLETE_PROFILE",
  "READY_TO_ADD_TO_WAITLIST" = "READY_TO_ADD_TO_WAITLIST",
  "ON_WAITLIST" = "ON_WAITLIST",
  "MISSING_REFERRAL_LETTER" = "MISSING_REFERRAL_LETTER",
  "ISSUED" = "ISSUED",
  "READY_TO_CREATE_VOUCHER" = "READY_TO_CREATE_VOUCHER",
  "INCOMPLETE_REFERRAL_INFORMATION" = "INCOMPLETE_REFERRAL_INFORMATION",
  "INCOMPLETE_IE_PROFILE" = "INCOMPLETE_IE_PROFILE",
}

export const useReadableStatus = () => {
  const { t } = useTranslation();

  const keyToReadableStatus = {
    INCOMPLETE_SIGN_UP: t("incompleteSignUp"),
    INCOMPLETE_PROFILE: t("incompleteProfile"),
    READY_TO_ADD_TO_WAITLIST: t("readyToAddToWaitlist"),
    ON_WAITLIST: t("onWaitlist"),
    MISSING_REFERRAL_LETTER: t("missingReferralLetter"),
    ISSUED: t("onCCFA"),
    READY_TO_CREATE_VOUCHER: t("readyForCCFA"),
    INCOMPLETE_REFERRAL_INFORMATION: t("incompleteReferralInformation"),
    INCOMPLETE_IE_PROFILE: t("Incomplete Income Eligible Information"),
  };

  const getReadableCompletedStatus = (key: AuthorizationStatus): string => {
    return _.get(keyToReadableStatus, key, key);
  };

  return { getReadableCompletedStatus };
};

export const authorizationUpdate = ({
  authorizationId,
  data,
}: {
  authorizationId: number;
  data: Partial<
    Omit<IFamily["vouchers"][number], "children"> & { children: string[] }
  >;
}) =>
  post(
    `${BASE_URL}/api/family-members/authorization/${authorizationId}/update/`,
    data
  );

export const incomeEligibleParentAutohrizationUpdate = ({
  authorizationId,
  parentId,
  data,
}: {
  authorizationId: number;
  parentId: number;
  data: {
    has_rental_income?: boolean;
    total_rental_income?: number;
    is_resident_at_rental_property?: boolean;
    has_lottery_earnings?: boolean;
    total_lottery_earnings?: number;
    has_alimony?: boolean;
    total_alimony?: number;
    has_workers_compensation?: boolean;
    total_workers_compensation?: number;
    has_unemployment_benefits?: boolean;
    total_unemployment_benefits?: number;
    has_retirement_income?: boolean;
    total_retirement_income?: number;
    total_enrolled_course_credits?: number;
    total_hours_a_week_in_school_or_training?: number;
    hours_per_day_seeking_work_or_training?: number;
    total_monthly_wages?: number;
    total_hours_per_week?: number;
    total_monthly_income?: number;
    month_year_reported?: string;
    gross_total_receipts_or_sales_in_month?: number;
    advertising?: number;
    automobile_expenses?: number;
    commissions_and_fees?: number;
    cost_of_goods_sold?: number;
    contact_labour?: number;
    employee_benefit_programs?: number;
    insurance?: number;
    interest_paid_on_mortgage_owed_banks?: number;
    other_interest_payment?: number;
    legal_and_professional_services?: number;
    office_expenses?: number;
    pension_or_profit_sharing_plan?: number;
    repair_and_maintenance?: number;
    rent_for_leased_vehicle_machinery_equipment?: number;
    rent_for_other_business_property?: number;
    supplies?: number;
    taxes_and_licenses?: number;
    transportation?: number;
    utilities?: number;
    wages_and_salaries_for_employees?: number;
    total_expenses?: number;
    net_income?: number;
  };
}) =>
  post(
    `${BASE_URL}/api/family-members/income-eligible/${authorizationId}/parents/${parentId}/update/`,
    data
  );

export const incomeEligibleSetEligibleVerification = ({
  authorizationId,
  parentId,
  data,
}: {
  authorizationId: number;
  parentId: number;
  data: {
    has_wages_from_employment: boolean;
    has_self_employment_income: boolean;
    is_seeking_work_or_education: boolean;
    is_attending_school_or_training: boolean;
    has_other_income: boolean;
    has_assets_over_1m: boolean;
  };
}) =>
  post(
    `${BASE_URL}/api/family-members/income-eligible/${authorizationId}/parents/${parentId}/eligibility-verification/`,
    data
  );

export const authorizationHouseholdSizeUpdate = ({
  authorizationId,
  data,
}: {
  authorizationId: string;
  data: {
    household_size: number;
    annual_income_status: string;
    total_annual_income: "MORE_THAN" | "LESS_THAN" | "NOT_WORKING";
  };
}) =>
  post(
    `${BASE_URL}/api/family-members/missing-info/authorization/${authorizationId}/household-size/update/`,
    data
  );

export const authorizationSpecialCircumstancesUpdate = ({
  authorizationId,
  data,
}: {
  authorizationId: string;
  data: {
    family_has_experienced_domestic_violence: boolean;
    family_is_experiencing_homelessness: boolean;
    parent_has_a_disability: boolean;
    parent_is_a_member_of_the_us_military: boolean;
    parent_is_an_early_educator: boolean;
    parent_is_receiving_treatment_for_substance_abuse: boolean;
    teen_parent: boolean;
    child_only_needs_coverage_during_school_closures: boolean;
  };
}) =>
  post(
    `${BASE_URL}/api/family-members/missing-info/authorization/${authorizationId}/special-circumstance/update/`,
    data
  );
