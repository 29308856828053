import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import ParseFile from "components/ParseFile";
import Stack from "components/Stack";
import Divider from "components/Divider";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";
import { useMyFamily } from "entities/Family/sdk";

const VerifyBusinessPage = () => {
  const { t } = useTranslation();
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parentId = parseInt(String(parentIdParam));
  const voucherId = parseInt(String(vouchersIdParam));

  const parent = family?.family_members?.find(
    (parent) => parent.id === parentId
  );

  if (!parentId || !voucherId || !family || !parent) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("verifyBusinessTitle")}
      backUrl={reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME, {
        voucherId,
        parentId,
      })}
      closeUrl={URLS.HOME}
    >
      <ParseFile
        parentId={parentId}
        voucherId={voucherId}
        family={family}
        documentParsingTypes={["BUSINESS_VERIFICATION"]}
        reloadFamily={reloadFamily}
        verificationFilter={(document) => document.is_business_verified}
        header={
          <>
            <Text>
              {t("verifyBusinessMessage1", {
                parentName: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
            <Text>{t("verifyBusinessMessage2")}</Text>

            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadFor")} {parent.first_name} {parent.last_name}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
          </>
        }
      />
    </FullPageDialog>
  );
};

export default VerifyBusinessPage;
