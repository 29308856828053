import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import DatePicker from "components/DatePicker";
import Box from "components/Box";
import FormHelperText from "components/FormHelperText";
import useMediaQuery from "components/useMediaQuery";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";
import DateInput from "components/DateInput";

import {
  IChildCreate,
  // childCreate,
  // childUpdate,
  useGenderOptions,
  useLanguageOptions,
  useRelationshipOptions,
} from "entities/FamilyMember/sdk";
import {
  childCreateAsSpecialist,
  childCreateInNewFamilyAsSpecialist,
} from "entities/Child/sdk";
import { IChildren } from "entities/FamilySpecialist/sdk";

import { colors } from "theme";

import { URLS } from "config/urls";
import { getDefaultLanguageValue } from "utils/language";
import { reverse } from "utils/urls";
// import { parsedCreateChild, parsedChildUpdate } from "entities/Documents/sdk";

interface IProps {
  childData?: IChildren;
  setStepname?: (stepName: string) => void;
  documentId?: number;
}

const AddChild = ({ childData, setStepname, documentId }: IProps) => {
  const { t, i18n } = useTranslation();
  const defaultLanguage = getDefaultLanguageValue(i18n.language);
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");

  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = familyIdParam ? parseInt(familyIdParam) : undefined;
  const location = useLocation();
  const isIncomeEligible = location.state?.isIncomeEligible;

  const genderOptions = useGenderOptions();
  const languageOptions = useLanguageOptions();
  const relationshipOptions = useRelationshipOptions();

  const { control, handleSubmit } = useForm<IChildCreate>({
    defaultValues: {
      first_name: childData?.first_name || "",
      last_name: childData?.last_name || "",
      date_of_birth: childData?.date_of_birth
        ? dayjs(childData?.date_of_birth).format("MM/DD/YYYY")
        : "",
      gender: childData?.gender || undefined,
      relationship: childData?.relationship || "",
      date_when_child_care_is_needed: childData?.date_when_child_care_is_needed
        ? dayjs(childData.date_when_child_care_is_needed).format("MM/DD/YYYY")
        : dayjs().format("MM/DD/YYYY"),
      preferred_language: childData?.preferred_language || undefined,
      child_has_a_disability: childData?.child_has_a_disability || false,
      child_is_in_head_start_and_needs_more_hours_of_coverage:
        childData?.child_is_in_head_start_and_needs_more_hours_of_coverage ||
        false,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    relationship: "",
    date_when_child_care_is_needed: "",
    preferred_language: "",
  });

  const onSubmit = async (data: IChildCreate) => {
    let valid = true;
    const newErrors = {
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      relationship: "",
      date_when_child_care_is_needed: "",
      preferred_language: "",
    };
    if (!documentId && !familyId) {
      if (!data.first_name) {
        newErrors.first_name = t("fieldRequired");
        valid = false;
      }
      if (!data.last_name) {
        newErrors.last_name = t("fieldRequired");
        valid = false;
      }
      if (!data.date_of_birth) {
        newErrors.date_of_birth = t("fieldRequired");
        valid = false;
      }
      if (!data.gender) {
        newErrors.gender = t("fieldRequired");
        valid = false;
      }
      if (!data.relationship) {
        newErrors.relationship = t("fieldRequired");
        valid = false;
      }
      if (!data.date_when_child_care_is_needed && !documentId) {
        newErrors.date_when_child_care_is_needed = t("fieldRequired");
        valid = false;
      }
      if (!data.preferred_language && !documentId) {
        newErrors.preferred_language = t("fieldRequired");
        valid = false;
      }

      setErrors(newErrors);
    }

    if (!valid) return;

    setIsSubmitting(true);
    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : null;

    const formattedDateWhenChildcareNeeded = dayjs(
      data.date_when_child_care_is_needed
    ).format("YYYY-MM-DD");
    const childCreateData = {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      date_of_birth: formattedBirthDate,
      preferred_language: data.preferred_language,
      relationship: data.relationship,
      date_when_child_care_is_needed: data.date_when_child_care_is_needed
        ? formattedDateWhenChildcareNeeded
        : "",
      child_has_a_disability: data.child_has_a_disability,
      child_is_in_head_start_and_needs_more_hours_of_coverage:
        data.child_is_in_head_start_and_needs_more_hours_of_coverage,
    };
    if (setStepname && documentId) {
      if (childData) {
        // const updatedChildData = {
        //   ...childCreateData,
        //   id: childData.id,
        // };
        // updateChild({ childId: childData.id, data: updatedChildData })
        //   .then(() => {
        //     setIsSubmitting(false);
        //     setStepname("children");
        //   })
        //   .catch(() => {
        //     const errorMessage = t("errorSavingData");
        //     toast.error(errorMessage);
        //     setIsSubmitting(false);
        //   });
      } else {
        // createChild({ familyId, data: childCreateData })
        //   .then(() => {
        //     setIsSubmitting(false);
        //     setStepname("children");
        //   })
        //   .catch(() => {
        //     const errorMessage = t("errorSavingData");
        //     toast.error(errorMessage);
        //     setIsSubmitting(false);
        //   });
      }
    } else {
      try {
        if (familyId) {
          if (isIncomeEligible) {
            await childCreateAsSpecialist(familyId, childCreateData);
            navigate(reverse(URLS.FAMILY_SPECIALIST_CHILDREN, { familyId }), {
              state: { isIncomeEligible },
            });
          } else {
            // This is used only in the Waitlist flow. We need to attach the child to the only Authorization for this family.
            await childCreateInNewFamilyAsSpecialist(familyId, childCreateData);
            navigate(reverse(URLS.FAMILY_SPECIALIST_CHILDREN, { familyId }));
            // } else {
            //   await childCreate(childCreateData);
            //   navigate(URLS.FAMILY_INFORMATION_CHILDREN);
          }
        }
      } catch (error: any) {
        toast.error(
          t("errorSavingData") + (error.response?.data?.message || "")
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Stack alignItems={"center"} sx={{ height: "calc(100vh - 180px)" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%", height: "100%" }}
        >
          <Stack
            sx={{ height: "100%" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack sx={{ width: matches ? "400px" : "100%" }}>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("firstName")}
                    fullWidth
                    margin="normal"
                    error={!!errors.first_name}
                    helperText={errors.first_name}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("lastName")}
                    fullWidth
                    margin="normal"
                    error={!!errors.last_name}
                    helperText={errors.last_name}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("gender")}
                    options={genderOptions}
                    fullWidth
                    margin="normal"
                    error={!!errors.gender}
                    helperText={errors.gender}
                  />
                )}
              />

              <Controller
                name="preferred_language"
                control={control}
                defaultValue={defaultLanguage}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("preferredLanguage")}
                    options={languageOptions}
                    fullWidth
                    margin="normal"
                    error={!!errors.preferred_language}
                    helperText={errors.preferred_language}
                  />
                )}
              />
              <DateInput
                control={control}
                label={t("dateOfBirth")}
                name="date_of_birth"
                error={!!errors.date_of_birth}
                helperText={errors.date_of_birth}
                required={!documentId && !familyId}
              />

              <Controller
                name="relationship"
                control={control}
                defaultValue={relationshipOptions[0].value}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("relationship")}
                    options={relationshipOptions}
                    fullWidth
                    margin="normal"
                    error={!!errors.relationship}
                    helperText={errors.relationship}
                  />
                )}
              />
              <Controller
                name="date_when_child_care_is_needed"
                control={control}
                render={({ field }) => (
                  <Box sx={{ mt: "16px", mb: "8px" }}>
                    <DatePicker
                      {...field}
                      label={t("dateChildCare")}
                      initialValue={field.value}
                    />
                    {!!errors.date_when_child_care_is_needed && (
                      <FormHelperText error>
                        {errors.date_when_child_care_is_needed}
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
              <Controller
                name="child_has_a_disability"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("childHasADisability")}
                  />
                )}
              />
              <Controller
                name="child_is_in_head_start_and_needs_more_hours_of_coverage"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("chilsIsInHeadStart")}
                  />
                )}
              />
            </Stack>
            <Stack
              alignItems={matches ? "center" : "flex-end"}
              sx={{
                width: "100%",
                borderTop: matches ? "1px solid" : "none",
                borderColor: colors.outline,
              }}
            >
              <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
                <BasicButton
                  buttonType="submit"
                  label={t("next")}
                  isDisabled={isSubmitting}
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default AddChild;
