import {
  IDocument,
  IFamilyDetail,
  IFamilyMember,
  IVoucher,
} from "entities/FamilySpecialist/sdk";

const addId = (documents: IDocument[]) =>
  documents.map((d) => ({ document_id: d.id, ...d }));

export const getDocuments = ({
  family,
  voucher,
  parent,
  isFamilyCreator,
}: {
  family: IFamilyDetail;
  voucher: IVoucher;
  parent: IFamilyMember;
  isFamilyCreator: boolean;
}) => {
  const documentsFilter = (d: IDocument) => {
    if (!isFamilyCreator) {
      return (
        d.vouchers.find((v) => v.id === voucher.id) &&
        d.parent?.id === parent.id
      );
    } else {
      return (
        (d.vouchers.find((v) => v.id === voucher.id) &&
          d.parent?.id === parent.id) ||
        d.residency_verification ||
        d.parsing_types.includes("FAMILY_RESIDENCY")
      );
    }
  };

  const documents = addId(family.documents.filter(documentsFilter));

  const idDocuments = documents.filter(
    (document) =>
      document.id_verification ||
      document.parsing_types.includes("PARENT_IDENTITY")
  );
  const militaryDocuments = documents.filter(
    (document) =>
      document.military_active_duty_verification ||
      document.parsing_types.includes("PARENT_MILITARY_ACTIVE_DUTY")
  );
  const retirementDocuments = documents.filter(
    (document) =>
      document.military_retirement_verification ||
      document.parsing_types.includes("PARENT_MILITARY_RETIRED")
  );
  const schoolOrTrainingDocuments = documents.filter(
    (document) =>
      document.school_or_training_verification ||
      document.parsing_types.includes("SCHOOL_OR_TRAINING")
  );
  const employmentDocuments = documents.filter(
    (document) =>
      document.wage_from_employment_verification ||
      document.parsing_types.includes("WAGES_FROM_EMPLOYMENT")
  );
  const selfEmploymentDocuments = documents.filter(
    (document) =>
      document.is_business_verified ||
      document.is_income_verified ||
      document.parsing_types.includes("BUSINESS_VERIFICATION") ||
      document.parsing_types.includes("INCOME_VERIFICATION")
  );

  const rentalIncomeDocuments = documents.filter(
    (document) =>
      document.rental_income_verification ||
      document.parsing_types.includes("RENTAL_INCOME")
  );
  const lotteryIncomeDocuments = documents.filter(
    (document) =>
      document.lottery_earnings_verification ||
      document.parsing_types.includes("LOTTERY_EARNINGS")
  );
  const alimonyDocuments = documents.filter(
    (document) =>
      document.alimony_verification ||
      document.parsing_types.includes("ALIMONY")
  );
  const workersCompensationDocuments = documents.filter(
    (document) =>
      document.workers_compensation_verification ||
      document.parsing_types.includes("WORKERS_COMPENSATION")
  );
  const unemploymentBenefitsDocuments = documents.filter(
    (document) =>
      document.unemployment_benefits_verification ||
      document.parsing_types.includes("UNEMPLOYMENT_BENEFITS")
  );
  const retirementIncomeDocuments = documents.filter(
    (document) =>
      document.retirement_income_verification ||
      document.parsing_types.includes("RETIREMENT_INCOME")
  );
  const residencyDocuments = documents.filter(
    (document) =>
      document.residency_verification ||
      document.parsing_types.includes("FAMILY_RESIDENCY")
  );

  return {
    documents,
    idDocuments,
    militaryDocuments,
    retirementDocuments,
    schoolOrTrainingDocuments,
    employmentDocuments,
    selfEmploymentDocuments,
    rentalIncomeDocuments,
    lotteryIncomeDocuments,
    alimonyDocuments,
    workersCompensationDocuments,
    unemploymentBenefitsDocuments,
    retirementIncomeDocuments,
    residencyDocuments,
  };
};
