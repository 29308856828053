import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Control, Controller, FieldErrors } from "react-hook-form";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import FullPageDialog from "components/FullPageDialog";
import TextField from "components/TextField";
import ParseFile from "components/ParseFile";

import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import useIncomeEligibleFlowNavigation from "../hooks";

const EmploymentForm = ({
  control,
  errors,
}: {
  control: Control<any, any>;
  errors: FieldErrors<any>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name="total_monthly_wages"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("totalMonthlyWagesLabel")}
            fullWidth
            InputLabelProps={{
              shrink:
                field.value !== "" &&
                field.value !== null &&
                field.value !== undefined,
            }}
            margin="normal"
            type="number"
            error={!!errors.total_monthly_wages}
          />
        )}
      />
      <Controller
        name="total_hours_per_week"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("hoursAWeekLabel")}
            fullWidth
            InputLabelProps={{
              shrink:
                field.value !== "" &&
                field.value !== null &&
                field.value !== undefined,
            }}
            margin="normal"
            type="number"
            error={!!errors.total_hours_per_week}
          />
        )}
      />
    </>
  );
};

const EmploymentPage = () => {
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const parentId = parseInt(String(parentIdParam));
  const { t } = useTranslation();
  const { getBackUrl } = useIncomeEligibleFlowNavigation();

  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );
  const voucherId = vouchersIdParam ? parseInt(vouchersIdParam) : undefined;

  if (!parent || !family || !voucherId) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("employmentTitle")}
      backUrl={getBackUrl({ family })}
      closeUrl={URLS.HOME}
    >
      <ParseFile
        parentId={parentId}
        voucherId={voucherId}
        family={family}
        documentParsingTypes={["WAGES_FROM_EMPLOYMENT"]}
        getDefaultValues={(parentIncomeEligible) => ({
          total_monthly_wages: parentIncomeEligible?.total_monthly_wages,
          total_hours_per_week: parentIncomeEligible?.total_hours_per_week,
        })}
        fieldsMapping={{
          monthly_wages: "total_monthly_wages",
          hours_per_week: "total_hours_per_week",
        }}
        FormComponent={EmploymentForm}
        reloadFamily={reloadFamily}
        verificationFilter={(document) =>
          document.wage_from_employment_verification
        }
        header={
          <>
            <Text variant="bodyLarge" color={colors.onSurface}>
              {t("provideDocumentation", {
                parent: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
            <Text variant="titleMedium" color={colors.onSurface}>
              {t("commonExamples")}
            </Text>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadFor")} {parent.first_name} {parent.last_name}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
          </>
        }
      />
    </FullPageDialog>
  );
};

export default EmploymentPage;
