import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import AddButton from "components/AddButton";
import IconButton from "components/IconButton";
import Link from "components/Link";

import theme, { colors } from "theme";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import { useMyFamily } from "entities/Family/sdk";
import { EditIcon } from "icons";
import useIncomeEligibleFlowNavigation from "../hooks";

const ParentsListPage = () => {
  const { t } = useTranslation();
  const { data: family } = useMyFamily();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();

  const { navigateNext, getBackUrl } = useIncomeEligibleFlowNavigation();

  if (!family || !voucherIdParam) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("parents")}
      backUrl={getBackUrl({ family })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
          <div style={{ margin: theme.spacing(2, 0) }}>
            <Text
              component="p"
              variant="titleMedium"
              color={colors.onSurface}
              sx={{ mb: theme.spacing(2) }}
            >
              {t("addParentsInstruction")}
            </Text>
            <Text component="p" variant="bodyMedium" color={colors.onSurface}>
              {t("pleaseInclude")}
            </Text>
            <ul>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeSpouse")}
                </Text>
              </li>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeOtherParent")}
                </Text>
              </li>
            </ul>
          </div>
          <Stack gap={theme.spacing(2)} flex={1} alignItems="center">
            {family.family_members.map((parent) => (
              <Stack
                direction="row"
                key={parent.id}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                  borderColor: colors.outline,
                  padding: theme.spacing(1),
                  width: "100%",
                }}
              >
                <Stack gap="15px">
                  <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                    {`${parent.first_name} ${parent.last_name}`.trim() === "" &&
                      "Unknown name"}
                    {parent.first_name} {parent.last_name}
                  </Text>
                </Stack>
                <Link
                  to={reverse(URLS.VERIFY_INCOME_EDIT_PARENT, {
                    parent: parent.id,
                    voucherId: voucherIdParam,
                  })}
                >
                  <IconButton
                    sx={{
                      height: "40px",
                      width: "40px",
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Link>
              </Stack>
            ))}
            <Link
              to={reverse(URLS.VERIFY_INCOME_ADD_PARENT, {
                voucherId: voucherIdParam,
              })}
            >
              <AddButton
                label={t("addParent")}
                backgroundColor={colors.secondaryContainer}
              />
            </Link>
          </Stack>{" "}
        </Container>
        <div
          style={{
            padding: theme.spacing(2, 3),
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={() => navigateNext({ family })}
          />
        </div>
      </Stack>
    </FullPageDialog>
  );
};
export default ParentsListPage;
