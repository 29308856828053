import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Box from "components/Box";

import overviewVideoEnglish from "assets/overview_video_english.mp4";
import overviewVideoSpanish from "assets/overview_video_spanish.mp4";
import incomeEligibleVideoEnglish from "assets/ie_flow_video_en.mp4";

import { getLanguage } from "utils/storage";

interface OverviewFormProps {
  is_income_eligible?: boolean;
}

const OverviewForm = ({ is_income_eligible = false }: OverviewFormProps) => {
  const { t } = useTranslation();
  const storedLanguage = getLanguage();

  return (
    <>
      <Stack alignItems="center">
        <Text variant="titleMedium" sx={{ mt: 2, mb: 2 }}>
          {t("financialAssistanceHelp")}
        </Text>
        <Text variant="titleMedium" sx={{ mt: 2 }}>
          {t("learnMoreVideo")}
        </Text>
        <Box
          sx={{
            maxWidth: storedLanguage === "es" ? "800px" : "600px",
            width: "100%",
            height: "auto",
            mt: 2,
            mb: 2,
            overflow: "hidden",
          }}
        >
          <video
            width="100%"
            height="100%"
            controls
            style={{ display: "block", width: "100%", height: "100%" }}
          >
            <source
              src={
                is_income_eligible
                  ? incomeEligibleVideoEnglish
                  : storedLanguage === "es"
                    ? overviewVideoSpanish
                    : overviewVideoEnglish
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Text variant="titleMedium" sx={{ mt: "5px" }}>
          {t("navigateProcessHelp")}
        </Text>
      </Stack>
    </>
  );
};

export default OverviewForm;
