import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import Chip from "components/Chip";

import { useSignedUser } from "entities/Auth/sdk";
import { CompletedStatus } from "entities/FamilySpecialist/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { ProfileIcon, AttentionIcon, CalendarIcon } from "icons";
import { colors } from "theme";
import { URLS } from "config/urls";
import { formatToCamelCase } from "utils/format";
import { useReadableStatusFamilyMember } from "entities/FamilyMember/sdk";
import { reverse } from "utils/urls";

const Home = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const { t } = useTranslation();
  const { getReadableCompletedStatus } = useReadableStatusFamilyMember();
  const { data: user } = useSignedUser();

  const handleClick = () => {
    const lastVoucherWithMissingData = _.findLast(
      _.orderBy(family?.missing_fields, "voucher_id"),
      (voucher) => voucher.has_family_member_missing_data
    );

    const voucherId = lastVoucherWithMissingData?.voucher_id;
    const voucher = family?.vouchers.find((v) => v.id === voucherId);
    if (!voucher) return;

    if (voucher.type === "INCOME_ELIGIBLE") {
      navigate(
        reverse(URLS.VERIFY_INCOME_PRIMARY_CONTACT, {
          voucherId: voucher.id,
        })
      );
    } else {
      navigate(
        reverse(URLS.MISSING_INFORMATION_PRIMARY_CONTACT, {
          voucherId: voucher.id,
        })
      );
    }
  };

  const handleButtonClick = () => {
    navigate(URLS.GET_HELP);
  };

  const openMailToLink = () => {
    window.location.href = "mailto:info@childcarecircuit.org";
  };

  const hasMissingFields =
    _.some(family?.missing_fields, (missingField) => {
      return (
        missingField.has_family_member_missing_data &&
        _.some(family?.vouchers, (voucher) => {
          return (
            voucher.id === missingField.voucher_id &&
            (voucher.status === "INCOMPLETE_REFERRAL_INFORMATION" ||
              voucher.status === "INCOMPLETE_PROFILE")
          );
        })
      );
    }) || false;

  if (!user) return null;

  return (
    <Stack
      sx={{ height: "calc(100vh - 100px)", justifyContent: "space-between" }}
    >
      <Stack gap="15px">
        {hasMissingFields && (
          <Stack
            onClick={handleClick}
            direction="row"
            gap="15px"
            alignItems="center"
            sx={{
              backgroundColor: colors.secondaryContainer,
              padding: "15px",
              cursor: "pointer",
            }}
          >
            <AttentionIcon width="56px" height="56px" />
            <Stack>
              <Text variant="titleMedium" sx={{ mt: 2 }}>
                {t("actionNeeded")}:
              </Text>
              <Text variant="titleMedium" sx={{ mb: 1 }}>
                {t("completeFamilyInformation")}
              </Text>
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          gap="15px"
          alignItems="center"
          sx={{ backgroundColor: colors.surfaceContainer, padding: "15px" }}
        >
          <ProfileIcon width="56px" height="56px" />
          {user.vouchers &&
            user.vouchers[user.vouchers.length - 1]?.status !==
              "ON_WAITLIST" && (
              <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
                {t("myStatus", {
                  currentStatus: getReadableCompletedStatus(
                    user.vouchers[user.vouchers.length - 1]
                      ?.status as CompletedStatus
                  ),
                })}
              </Text>
            )}
          {user.vouchers &&
            user.vouchers[user.vouchers.length - 1]?.status ===
              "ON_WAITLIST" && (
              <Stack>
                <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
                  {user.first_name} {user.last_name}
                </Text>
                <Text variant="titleMedium" sx={{ mb: 1 }}>
                  {user.vouchers[user.vouchers.length - 1]?.type &&
                    t(
                      formatToCamelCase(
                        user.vouchers[user.vouchers.length - 1]?.type
                      )
                    )}
                </Text>
                <Text variant="titleMedium" sx={{ mb: 1 }}>
                  {user.vouchers[user.vouchers.length - 1]?.status &&
                    getReadableCompletedStatus(
                      user.vouchers[user.vouchers.length - 1]
                        ?.status as CompletedStatus
                    )}
                </Text>
                {user.household_id && (
                  <Chip
                    label={`${t("householdId")}: ${user.household_id}`}
                    sx={{
                      border: `1px solid ${colors.outline}`,
                      color: colors.onSurfaceVariant,
                      backgroundColor: colors.surfaceDim,
                      borderRadius: "8px",
                    }}
                  />
                )}
              </Stack>
            )}
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          alignItems="center"
          sx={{
            backgroundColor: colors.surfaceContainer,
            padding: "15px",
            cursor: "pointer",
          }}
          onClick={openMailToLink}
        >
          <CalendarIcon width={matches ? "56px" : "86px"} height="56px" />
          <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
            {t("sendAMessageToMyFamilyAdministrator")}
          </Text>
        </Stack>
      </Stack>
      {!matches && (
        <Stack
          alignItems="center"
          sx={{
            backgroundColor: colors.surfaceContainer,
            position: "absolute",
            bottom: 0,
            width: "100%",
            left: 0,
            height: "115px",
            borderRadius: "10px",
          }}
        >
          <BasicButton
            label={t("needHelp")}
            width={matches ? "322px" : "161px"}
            height="56px"
            onClick={handleButtonClick}
            sx={{ borderRadius: "16px", position: "absolute", bottom: "85px" }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Home;
