import { useState, useEffect } from "react";
import _ from "lodash";

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { URLS } from "config/urls";

import Home from "pages/Home";
import LoginPage from "pages/Login";
import InitialPage from "pages/Initial";
import SignUpPage from "pages/SignUp";
import RegistrationVerificationPage from "pages/RegistrationVerification";
import ChildCareNeedsPage from "pages/ChildCareNeeds";
import FamilyInformationPage from "pages/FamilyInformation";
import MagicLinkPage from "pages/MagicLink";
import Overview from "pages/FamilyInformation/components/Overview";
import AboutYou from "pages/FamilyInformation/components/AboutYou";
import YourStatus from "pages/FamilyInformation/components/YourStatus";
import MailingAddress from "pages/FamilyInformation/components/MailingAddress";
import Children from "pages/FamilyInformation/components/Children";
import AddChild from "pages/FamilyInformation/components/AddChild";
import AdditionalInformation from "pages/FamilyInformation/components/AdditionalInformation";
import Complete from "pages/FamilyInformation/components/Complete";
import LoginVerificationPage from "pages/LoginVerification";
import FamilySpecialistHome from "pages/FamilySpecialist/Home";
import FamilyListPage from "pages/FamilySpecialist/FamilyList";
import FamilyDetailPage from "pages/FamilySpecialist/FamilyDetail";
import GetHelpPage from "pages/GetHelp";
import ThankYouPage from "pages/ThankYou";
import ChildNeedsForm from "pages/ChildCareNeeds/components/ChildNeedsForm";
import CheckNeeds from "pages/ChildCareNeeds/components/CheckNeeds";
import UpdateInformation from "pages/ChildCareNeeds/components/UpdateInformation";
import IncomeVerification from "pages/FamilyInformation/components/IncomeVerification";
import HouseholdDefinitions from "pages/FamilyInformation/components/HouseholdDefinitions";
import AuthorizationType from "pages/FamilyInformation/components/AuthorizationType";
import WaitlistPrimaryContact from "pages/FamilyInformation/components/WaitlistPrimaryContact";
import IncomeEligiblePrimaryContact from "pages/FamilyInformation/components/IncomeEligiblePrimaryContact";
import DocumentsContainerPrimaryContact from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/PrimaryContact";
import DocumentsContainerParentStatus from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParentStatus";
import DocumentsContainerChildrenList from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/Children";
import DocumentsContainerParentEdit from "pages/FamilyInformation/components/ParsedParentEditPage";
import DocumentsContainerParentAdd from "pages/FamilyInformation/components/ParsedParentAddPage";
import DocumentsContainerChildrenAdd from "pages/FamilyInformation/components/ParsedChildAdd";
import DocumentsContainerChildrenEdit from "pages/FamilyInformation/components/ParsedChildEdit";
import DocumentContainerAuthorizationTypeEdit from "pages/FamilyInformation/components/AuthorizationTypeEdit";
import DocumentContainerPlacementList from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParsedPlacementList";
import DocumentContainerPlacementAdd from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParsedPlacementAdd";
import DocumentContainerPlacementEdit from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParsedPlacementEdit";
import DocumentContainerAssign from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/Assign";
import Parents from "pages/FamilyInformation/components/Parents";
import ParentAddAsSpecialistPage from "pages/FamilySpecialist/ParentAddAsSpecialistPage";
import SpecialCircumstance from "pages/FamilyInformation/components/SpecialCircumstance";
import MessagesListPage from "pages/FamilySpecialist/MessagesList";
import ExpiredMagicLink from "pages/ExpiredMagicLink";
import Admin from "pages/FamilySpecialist/Admin";

import PrimaryContactPage from "pages/FamilyInformation/PrimaryContactPage";
import ParentsListPage from "pages/FamilyInformation/ParentsListPage";
import ParentAddPage from "pages/FamilyInformation/ParentAddPage";
import ParentEditPage from "pages/FamilyInformation/ParentEditPage";
import ChildListPage from "pages/FamilyInformation/ChildListPage";
import ChildAddPage from "pages/FamilyInformation/ChildAddPage";
import ChildEditPage from "pages/FamilyInformation/ChildEditPage";

import MissingInformationPrimaryContactPage from "pages/MissingInformation/PrimaryContactPage";
import MissingInformationParentsListPage from "pages/MissingInformation/ParentsListPage";
import MissingInformationParentAddPage from "pages/MissingInformation/ParentAddPage";
import MissingInformationParentEditPage from "pages/MissingInformation/ParentEditPage";
import MissingInformationChildListPage from "pages/MissingInformation/ChildListPage";
import MissingInformationChildAddPage from "pages/MissingInformation/ChildAddPage";
import MissingInformationChildEditPage from "pages/MissingInformation/ChildEditPage";
import MissingInformationVerificationRedirectToParentPage from "pages/MissingInformation/VerificationRedirectToParentPage";
import MissingInformationVerificationPage from "pages/MissingInformation/VerificationPage";
// import MissingInformationAuthorizationsListPage from "pages/MissingInformation/AuthorizationsListPage";
// import MissingInformationAuthorizationEditPage from "pages/MissingInformation/AuthorizationEditPage";
import MissingInformationPlacementListPage from "pages/MissingInformation/PlacementListPage";
import MissingInformationPlacementAddPage from "pages/MissingInformation/PlacementAddPage";
import MissingInformationPlacementEditPage from "pages/MissingInformation/PlacementEditPage";
import MissingInformationProviderConfirmationPage from "pages/MissingInformation/ProviderConfirmationPage";
import MissingInformationIncomeVerificationPage from "pages/MissingInformation/IncomeVerificationPage";
import MissingInformationSpecialCircumstancePage from "pages/MissingInformation/SpecialCircumstancePage";
import MissingInformationSuccessReferralPage from "pages/MissingInformation/SuccessReferralPage";
import MissingInformationSuccessWaitlistPage from "pages/MissingInformation/SuccessWaitlistPage";

import VerifyIncomeFlowContactInformation from "pages/VerifyIncomeFlow/ContactInfoPage";
import VerifyIncomeChildCareNeeds from "pages/VerifyIncomeFlow/ChildCareNeedsPage";
import VerifyIncomeOverview from "pages/VerifyIncomeFlow/OverviewPage";
import VerifyIncomeParentsList from "pages/VerifyIncomeFlow/ParentsListPage";
import VerifyIncomeChildList from "pages/VerifyIncomeFlow/ChildrenListPage";
import VerifyIncomeChildAddPage from "pages/VerifyIncomeFlow/ChildAddPage";
import VerifyIncomeChildEditPage from "pages/VerifyIncomeFlow/ChildEditPage";
import VerifyIncomeParentAddPage from "pages/VerifyIncomeFlow/ParentAddPage";
import VerifyIncomeParentEditPage from "pages/VerifyIncomeFlow/ParentEditPage";
import VerifyIncomeDependentsList from "pages/VerifyIncomeFlow/DependentsListPage";
import VerifyIncomeDependentAddPage from "pages/VerifyIncomeFlow/DependentAddPage";
import VerifyIncomeDependentEditPage from "pages/VerifyIncomeFlow/DependentEditPage";
import VerifyIncomeVerifyParentIdentityPage from "pages/VerifyIncomeFlow/VerifyParentIdentityPage";
import VerifyIncomeVerifyChildCitizenshipPage from "pages/VerifyIncomeFlow/VerifyChildCitizenshipPage";
import VerifyIncomeMilitaryOrRetiredPage from "pages/VerifyIncomeFlow/MilitaryOrRetiredPage";
import VerifyIncomeVerifyChildRelationshipPage from "pages/VerifyIncomeFlow/VerifyChildRelationshipPage";
import VerifyIncomeVerifyChildAgePage from "pages/VerifyIncomeFlow/VerifyChildAgePage";
import VerifyIncomeVerifyResidencyPage from "pages/VerifyIncomeFlow/VerifyResidencyPage";
import VerifyIncomeVerifyMilitaryPage from "pages/VerifyIncomeFlow/VerifyMilitaryPage";
import VerifyIncomeVerifyRetirementPage from "pages/VerifyIncomeFlow/VerifyRetirementPage";
import VerifyIncomeSchoolOrTrainingPage from "pages/VerifyIncomeFlow/SchoolOrTrainingPage";
import VerifyIncomeSeekingActivityPage from "pages/VerifyIncomeFlow/SeekingActivityPage";
import VerifyIncomeEmploymentPage from "pages/VerifyIncomeFlow/EmploymentPage";
import VerifyIncomeVerifySelfEmploymentIncomePage from "pages/VerifyIncomeFlow/VerifySelfEmploymentIncomePage";
import VerifyIncomeVerifySelfEmploymentIncomeFormPage from "pages/VerifyIncomeFlow/VerifySelfEmploymentIncomeFormPage";
import VerifyIncomeVerifySelfEmploymentIncomeUploadPage from "pages/VerifyIncomeFlow/VerifySelfEmploymentIncomeUploadPage";
import VerifyIncomeVerifyBusinessPage from "pages/VerifyIncomeFlow/VerifyBusinessPage";
import VerifyIncomeVerifyInformationPage from "pages/VerifyIncomeFlow/VerifyInformationPage";
import VerifyIncomePlacementListPage from "pages/VerifyIncomeFlow/PlacementListPage";
import VerifyIncomePlacementAddPage from "pages/VerifyIncomeFlow/PlacementAddPage";
import VerifyIncomePlacementEditPage from "pages/VerifyIncomeFlow/PlacementEditPage";
import VerifyIncomeProviderConfirmation from "pages/VerifyIncomeFlow/ProviderConfirmationPage";
import VerifyIncomeSuccessPage from "pages/VerifyIncomeFlow/SuccessPage";

import Layout from "components/Layout";
import FamilyMemberMissingInfoLayout from "components/Layout/FamilyMemberMissingInfoLayout";
import FamilyMemberVerifyIncomeLayout from "components/Layout/FamilyMemberVerifyIncomeLayout";
import DocumentsLayout from "pages/FamilyInformation/components/DocumentsLayout";
import AnonimousUserLayout from "components/AnonimousUserLayout";

import { getSignedUser, UserType } from "entities/Auth/sdk";

import { CircularProgress } from "components/Loading";
import VerifyEligibilityPage from "pages/VerifyIncomeFlow/VerifyEligibilityPage";
import OtherIncomePage from "pages/VerifyIncomeFlow/OtherIncomePage";
import RentalIncomePage from "pages/VerifyIncomeFlow/RentalIncomePage";
import LotteryEarningsPage from "pages/VerifyIncomeFlow/LotteryEarningsPage";
import AlimonyPage from "pages/VerifyIncomeFlow/AlimonyPage";
import WorkersCompensationPage from "pages/VerifyIncomeFlow/WorkersCompensationPage";
import UnemploymentPage from "pages/VerifyIncomeFlow/UnemploymentPage";
import RetirementPage from "pages/VerifyIncomeFlow/RetirementPage";

const LoginGuard = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    getSignedUser()
      .then(() => {
        setSignedIn(true);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          navigate("/");
        } else {
          // TODO
          navigate("/");
        }
      });
  }, []);

  if (signedIn) {
    return children;
  }
  return (
    <div
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const InitialRedirect = () => {
  useEffect(() => {
    getSignedUser()
      .then((response) => {
        const user = response.data;
        if (user.user_type === UserType.FAMILY_SPECIALIST) {
          window.location.href = URLS.FAMILY_SPECIALIST_FAMILY_LIST;
        }

        if (user.user_type === UserType.FAMILY_MEMBER) {
          if (
            user.vouchers.length === 1 &&
            user.vouchers[0].type == "UNKNOWN"
          ) {
            window.location.href = URLS.CHILD_CARE_NEEDS;
          } else {
            window.location.href = URLS.HOME;
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/welcome";
        }
      });
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <InitialRedirect />,
  },
  {
    path: "/welcome",
    element: <InitialPage />,
  },
  {
    path: "",
    element: <AnonimousUserLayout />,
    children: [
      {
        path: URLS.LOGIN,
        element: <LoginPage />,
      },
      {
        path: URLS.SIGNUP,
        element: <SignUpPage />,
      },
      {
        path: URLS.REGISTRATION_VERIFY,
        element: <RegistrationVerificationPage />,
      },
      {
        path: URLS.LOGIN_VERIFY,
        element: <LoginVerificationPage />,
      },
      {
        path: URLS.MAGIC_LINK,
        element: <MagicLinkPage />,
      },
      {
        path: URLS.GET_HELP,
        element: <GetHelpPage />,
      },
      {
        path: URLS.THANK_YOU,
        element: <ThankYouPage />,
      },
      {
        path: URLS.EXPIRED_MAGIC_LINK,
        element: <ExpiredMagicLink />,
      },
    ],
  },
  {
    path: "/missing-information/:voucherId",
    element: (
      <LoginGuard>
        <FamilyMemberMissingInfoLayout />
      </LoginGuard>
    ),
    children: [
      {
        path: "primary-contact",
        element: <MissingInformationPrimaryContactPage />,
      },
      { path: "parents", element: <MissingInformationParentsListPage /> },
      { path: "add-parent", element: <MissingInformationParentAddPage /> },
      {
        path: "parent/:parentId/edit",
        element: <MissingInformationParentEditPage />,
      },
      { path: "children", element: <MissingInformationChildListPage /> },
      { path: "add-child", element: <MissingInformationChildAddPage /> },
      {
        path: "child/:childId/edit",
        element: <MissingInformationChildEditPage />,
      },
      {
        path: "verification",
        element: <MissingInformationVerificationRedirectToParentPage />,
      },
      {
        path: "verification/parent/:parentId",
        element: <MissingInformationVerificationPage />,
      },
      // {
      //   path: "authorizations",
      //   element: <MissingInformationAuthorizationsListPage />,
      // },
      // {
      //   path: "authorization/:authorizationId/edit",
      //   element: <MissingInformationAuthorizationEditPage />,
      // },
      {
        path: "placements",
        element: <MissingInformationPlacementListPage />,
      },
      {
        path: "placements/add",
        element: <MissingInformationPlacementAddPage />,
      },
      {
        path: "placement/:placementId/edit",
        element: <MissingInformationPlacementEditPage />,
      },
      {
        path: "provider-confirmation/placement/:placementId",
        element: <MissingInformationProviderConfirmationPage />,
      },
      {
        path: "income-verification",
        element: <MissingInformationIncomeVerificationPage />,
      },
      {
        path: "special-circumstances",
        element: <MissingInformationSpecialCircumstancePage />,
      },
      {
        path: "referral-success",
        element: <MissingInformationSuccessReferralPage />,
      },
      {
        path: "waitlist-success",
        element: <MissingInformationSuccessWaitlistPage />,
      },
    ],
  },
  {
    path: "/verify-income/:voucherId",
    element: (
      <LoginGuard>
        <FamilyMemberVerifyIncomeLayout />
      </LoginGuard>
    ),
    children: [
      {
        path: "primary-contact",
        element: <VerifyIncomeFlowContactInformation />,
      },
      {
        path: "child-care-needs",
        element: <VerifyIncomeChildCareNeeds />,
      },
      {
        path: "overview",
        element: <VerifyIncomeOverview />,
      },
      {
        path: "parents-list",
        element: <VerifyIncomeParentsList />,
      },
      {
        path: "parent/:parentId/edit",
        element: <VerifyIncomeParentEditPage />,
      },
      {
        path: "add-parent",
        element: <VerifyIncomeParentAddPage />,
      },
      {
        path: "children-list",
        element: <VerifyIncomeChildList />,
      },
      { path: "add-child", element: <VerifyIncomeChildAddPage /> },
      {
        path: "child/:childId/edit",
        element: <VerifyIncomeChildEditPage />,
      },
      {
        path: "dependents-list",
        element: <VerifyIncomeDependentsList />,
      },
      { path: "add-dependent", element: <VerifyIncomeDependentAddPage /> },
      {
        path: "dependent/:dependentId/edit",
        element: <VerifyIncomeDependentEditPage />,
      },
      {
        path: "verification/parent/:parentId",
        element: <VerifyIncomeVerifyParentIdentityPage />,
      },
      {
        path: "verification/child-citizenship/:childId",
        element: <VerifyIncomeVerifyChildCitizenshipPage />,
      },
      {
        path: "verification/child-relationship/:childId",
        element: <VerifyIncomeVerifyChildRelationshipPage />,
      },
      {
        path: "verification/child-age/:childId",
        element: <VerifyIncomeVerifyChildAgePage />,
      },
      {
        path: "verification/residency",
        element: <VerifyIncomeVerifyResidencyPage />,
      },
      {
        path: "military-or-retired",
        element: <VerifyIncomeMilitaryOrRetiredPage />,
      },
      {
        path: "verify-eligibility/:parentId",
        element: <VerifyEligibilityPage />,
      },
      {
        path: "other-income/:parentId",
        element: <OtherIncomePage />,
      },
      {
        path: "rental-income/:parentId",
        element: <RentalIncomePage />,
      },
      {
        path: "lottery-earnings/:parentId",
        element: <LotteryEarningsPage />,
      },
      {
        path: "alimony/:parentId",
        element: <AlimonyPage />,
      },
      {
        path: "workers-compensation/:parentId",
        element: <WorkersCompensationPage />,
      },
      {
        path: "unemployment/:parentId",
        element: <UnemploymentPage />,
      },
      {
        path: "retirement/:parentId",
        element: <RetirementPage />,
      },
      {
        path: "verification/:parentId/military",
        element: <VerifyIncomeVerifyMilitaryPage />,
      },
      {
        path: "verification/:parentId/retirement",
        element: <VerifyIncomeVerifyRetirementPage />,
      },
      {
        path: "verification/:parentId/school-or-training",
        element: <VerifyIncomeSchoolOrTrainingPage />,
      },
      {
        path: "seeking-activity/:parentId",
        element: <VerifyIncomeSeekingActivityPage />,
      },
      {
        path: "employment/:parentId",
        element: <VerifyIncomeEmploymentPage />,
      },
      {
        path: "verification/:parentId/self-employment-income",
        element: <VerifyIncomeVerifySelfEmploymentIncomePage />,
      },
      {
        path: "verification/:parentId/self-employment-income-form",
        element: <VerifyIncomeVerifySelfEmploymentIncomeFormPage />,
      },
      {
        path: "verification/:parentId/self-employment-income-upload",
        element: <VerifyIncomeVerifySelfEmploymentIncomeUploadPage />,
      },
      {
        path: "verification/:parentId/business",
        element: <VerifyIncomeVerifyBusinessPage />,
      },
      {
        path: "verify-information",
        element: <VerifyIncomeVerifyInformationPage />,
      },
      {
        path: "placement-list",
        element: <VerifyIncomePlacementListPage />,
      },
      {
        path: "placement/add",
        element: <VerifyIncomePlacementAddPage />,
      },
      {
        path: "placement/:placementId/edit",
        element: <VerifyIncomePlacementEditPage />,
      },
      {
        path: "provider-confirmation/placement/:placementId",
        element: <VerifyIncomeProviderConfirmation />,
      },
      {
        path: "success",
        element: <VerifyIncomeSuccessPage />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <LoginGuard>
        <Layout />
      </LoginGuard>
    ),
    loader: () => <div>Loading ...</div>,
    errorElement: <div>Oops, something went wrong...</div>,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "child-care-needs/",
        element: <ChildCareNeedsPage />,
        children: [
          { path: "", element: <Navigate to="description" replace /> },
          { path: "description", element: <ChildNeedsForm /> },
          { path: "check-needs", element: <CheckNeeds /> },
          { path: "update-information", element: <UpdateInformation /> },
        ],
      },
      {
        path: "family-information/",
        element: <FamilyInformationPage />,
        children: [
          { path: "", element: <Navigate to="overview" replace /> },
          { path: "overview", element: <Overview /> },
          { path: "about-you", element: <AboutYou /> },
          { path: "your-status", element: <YourStatus /> },

          { path: "primary-contact", element: <PrimaryContactPage /> },
          { path: "parents", element: <ParentsListPage /> },
          { path: "add-parent", element: <ParentAddPage /> },
          { path: "parent/:parentId/edit", element: <ParentEditPage /> },
          { path: "mailing-address", element: <MailingAddress /> },
          { path: "children", element: <ChildListPage /> },
          { path: "add-child", element: <ChildAddPage /> },
          { path: "child/:childId/edit", element: <ChildEditPage /> },
          { path: "income-verification", element: <IncomeVerification /> },

          { path: "household-definitions", element: <HouseholdDefinitions /> },
          { path: "special-circumstances", element: <SpecialCircumstance /> },
          {
            path: "additional-information",
            element: <AdditionalInformation />,
          },
          { path: "complete", element: <Complete /> },
          { path: "*", element: <Navigate to="overview" replace /> },
        ],
      },
    ],
  },
  {
    path: "/family-specialist/",
    element: (
      <LoginGuard>
        <Layout />
      </LoginGuard>
    ),
    loader: () => <div>Loading ...</div>,
    errorElement: <div>Oops, something went wrong...</div>,
    children: [
      {
        path: "home",
        element: <FamilySpecialistHome />, // unused
      },
      {
        path: "family-list",
        element: <FamilyListPage />,
      },
      {
        path: "family-detail/:id",
        element: <FamilyDetailPage />,
      },
      {
        path: "family-information/",
        element: <FamilyInformationPage />,
        children: [
          { path: "", element: <Navigate to="authorization" replace /> },
          { path: "authorization", element: <AuthorizationType /> },
          { path: "primary-contact", element: <WaitlistPrimaryContact /> },
          {
            path: "income-eligible/primary-contact",
            element: <IncomeEligiblePrimaryContact />,
          },
          { path: "family/:familyId/your-status", element: <YourStatus /> },
          { path: "family/:familyId/parents", element: <Parents /> },
          {
            path: "family/:familyId/add-parent",
            element: <ParentAddAsSpecialistPage />,
          },
          {
            path: "family/:familyId/mailing-address",
            element: <MailingAddress />,
          },
          { path: "family/:familyId/children", element: <Children /> },
          { path: "family/:familyId/add-child", element: <AddChild /> }, // used
          {
            path: "family/:familyId/special-circumstances",
            element: <SpecialCircumstance />,
          },
          {
            path: "family/:familyId/additional-information",
            element: <AdditionalInformation />,
          },
          { path: "family/:familyId/complete", element: <Complete /> },
          {
            path: "documents/:documentId/",
            element: <DocumentsLayout />,
            children: [
              {
                path: "primary-contact",
                element: <DocumentsContainerPrimaryContact />,
              },
              {
                path: "parents",
                element: <DocumentsContainerParentStatus />,
              },
              {
                path: "parents/:parentId/edit",
                element: <DocumentsContainerParentEdit />,
              },
              {
                path: "parents/add",
                element: <DocumentsContainerParentAdd />,
              },
              {
                path: "children",
                element: <DocumentsContainerChildrenList />,
              },
              {
                path: "parsed-child/add",
                element: <DocumentsContainerChildrenAdd />,
              },
              {
                path: "parsed-child/:parsedChildId/edit",
                element: <DocumentsContainerChildrenEdit />,
              },
              {
                path: "authorization-type",
                element: <DocumentContainerAuthorizationTypeEdit />,
              },
              {
                path: "placement",
                element: <DocumentContainerPlacementList />,
              },
              {
                path: "placement/add",
                element: <DocumentContainerPlacementAdd />,
              },
              {
                path: "placement/:placementId/edit",
                element: <DocumentContainerPlacementEdit />,
              },
              {
                path: "family/:familyId/assign-specialist",
                element: <DocumentContainerAssign />,
              },
            ],
          },
          { path: "*", element: <Navigate to="authorization" replace /> },
        ],
      },
      {
        path: "messages-list",
        element: <MessagesListPage />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
    ],
  },
]);

const Routes = () => <RouterProvider router={router} />;

export default Routes;
