import Box from "components/Box";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import { EditIcon } from "icons";
import { colors } from "theme";
import Text from "components/Text";
import { IFamilyDetail, IVoucher } from "entities/FamilySpecialist/sdk";
import EditPanel from "entities/FamilyEdit/components";
import { useState } from "react";
import ChildIncomeEligibleDocumentsForm from "../ChildIncomeEligibleDocumentsForm";

interface IProps {
  child: {
    id: number;
    first_name: string;
    last_name: string;
  };
  voucher: IVoucher;
  family: IFamilyDetail;
  updateFamily: () => Promise<IFamilyDetail| undefined>;
}

const ChildBox = ({ child, voucher, family, updateFamily }: IProps) => {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const documents = family.documents.filter(
    (d) =>
      d.vouchers.find((v) => v.id === voucher.id) && d.child?.id === child.id
  );

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: colors.outline,
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Stack gap="15px">
        <Text variant="titleMedium">
          {child.first_name} {child.last_name}
        </Text>
        <Stack direction="row" gap="30px">
          <Text variant="titleMedium">{documents.length} Documents</Text>
        </Stack>
      </Stack>
      <IconButton
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          height: "40px",
          width: "40px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsEditOpen(true);
        }}
      >
        <EditIcon />
      </IconButton>
      <EditPanel
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        title={`${child.first_name} ${child.last_name} documents`}
      >
        <ChildIncomeEligibleDocumentsForm
          family={family}
          voucher={voucher}
          childId={child.id}
          updateFamily={updateFamily}
          onSubmit={async () => {
            await updateFamily();
            setIsEditOpen(false);
          }}
        />
      </EditPanel>
    </Box>
  );
};

export default ChildBox;
