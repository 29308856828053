import BasicButton from "components/BasicButton";
import Checkbox from "components/Checkbox";
import Container from "components/Container";
import FormControlLabel from "components/FormControlLabel";
import Text from "components/Text";
import Stack from "components/Stack";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import theme, { colors } from "theme";

interface IDefaultValues {
    has_rental_income: boolean,
    has_lottery_earnings: boolean,
    has_alimony: boolean,
    has_workers_compensation: boolean,
    has_unemployment_benefits: boolean,
    has_retirement_income: boolean,
}

interface IProps {
  defaultValues: IDefaultValues;
  parent: { first_name: string; last_name: string };
  onSave: (data: IDefaultValues) => void;
}

const OtherIncomeForm = ({onSave, defaultValues, parent}: IProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues,
  });

  return (
    <>
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Text
          component="p"
          variant="titleMedium"
          color={colors.onSurface}
          sx={{ mb: theme.spacing(2) }}
        >
          {t("whichOfTheFollowingApply", {
            parentName: `${parent.first_name} ${parent.last_name}`,
          })}
        </Text>
        <Stack gap={theme.spacing(2)} flex={1} sx={{ mt: theme.spacing(4) }}>
          <Controller
            name={"has_rental_income"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_rental_income")}
              />
            )}
          />
          <Controller
            name={"has_lottery_earnings"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_lottery_earnings")}
              />
            )}
          />
          <Controller
            name={"has_alimony"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_alimony")}
              />
            )}
          />
          <Controller
            name={"has_workers_compensation"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_workers_compensation")}
              />
            )}
          />
          <Controller
            name={"has_unemployment_benefits"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_unemployment_benefits")}
              />
            )}
          />
          <Controller
            name={"has_retirement_income"}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("label_has_retirement_income")}
              />
            )}
          />
        </Stack>
      </Container>
      <div
        style={{
          padding: theme.spacing(2, 3),
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BasicButton label={t("next")} onClick={handleSubmit(onSave)} />
      </div>
    </>
  );
};

export default OtherIncomeForm;
