import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Control, Controller, FieldErrors } from "react-hook-form";

import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import TextField from "components/TextField";
import ParseFile from "components/ParseFile";
import Stack from "components/Stack";
import Divider from "components/Divider";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";
import { useMyFamily } from "entities/Family/sdk";

const VerifySelfEmploymentIncomeUploadForm = ({
  control,
  errors,
}: {
  control: Control<any, any>;
  errors: FieldErrors<any>;
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="total_monthly_income"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          type="number"
          label={t("totalMonthlyIncome")}
          fullWidth
          InputLabelProps={{
            shrink:
              field.value !== "" &&
              field.value !== null &&
              field.value !== undefined,
          }}
          margin="normal"
          error={!!errors.total_lottery_earnings}
        />
      )}
    />
  );
};

const VerifySelfEmploymentIncomeUploadPage = () => {
  const { t } = useTranslation();
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parentId = parseInt(String(parentIdParam));
  const voucherId = parseInt(String(vouchersIdParam));

  const parent = family?.family_members?.find(
    (parent) => parent.id === parentId
  );

  if (!parentId || !voucherId || !family || !parent) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("verifySelfEmploymentIncomeTitle")}
      backUrl={reverse(URLS.VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME, {
        voucherId,
        parentId,
      })}
      closeUrl={URLS.HOME}
    >
      <ParseFile
        parentId={parentId}
        voucherId={voucherId}
        family={family}
        documentParsingTypes={["INCOME_VERIFICATION", "BUSINESS_VERIFICATION"]}
        getDefaultValues={(parentIncomeEligible) => ({
          total_monthly_income: parentIncomeEligible?.total_monthly_income,
        })}
        FormComponent={VerifySelfEmploymentIncomeUploadForm}
        reloadFamily={reloadFamily}
        verificationFilter={(document) =>
          document.is_income_verified || document.is_business_verified
        }
        fieldsMapping={{
          total_monthly_income: "total_monthly_income",
        }}
        verificationChips={{
          is_income_verified: "incomeVerified",
          is_business_verified: "businessVerified",
        }}
        header={
          <>
            <Text>
              {t("uploadDocumentsInstruction", {
                parentName: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
            <Text>{t("commonExamplesSelfIncome")}</Text>

            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadFor")} {parent.first_name} {parent.last_name}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
          </>
        }
      />
    </FullPageDialog>
  );
};

export default VerifySelfEmploymentIncomeUploadPage;
