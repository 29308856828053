import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import TextField from "components/TextField";
import WeeklySchedule from "components/WeeklySchedule";
import Box from "components/Box";
import Text from "components/Text";
import POWERFULSelect from "components/POWERFULSelect";

import { useMyFamily, IFamily } from "entities/Family/sdk";
import theme, { colors } from "theme";
import { useFormWarnings } from "utils/forms";
import { extractErrorMessage } from "utils/error";

interface IProps {
  initialValues?: Partial<IFamily["vouchers"][number]["placements"][number]>;
  initialVoucherId?: number;
  fieldsWithWarnings?: string[];
  onCancel: () => void;
  onSave: (values: any) => Promise<any>;
  isMissingFields?: boolean;
}

const PlacementForm = ({
  initialValues,
  initialVoucherId,
  onCancel,
  onSave,
  fieldsWithWarnings,
  isMissingFields = false,
}: IProps) => {
  const { t } = useTranslation();
  const childCareNeed = [
    { label: t("fullTime"), value: "FULL_TIME" },
    { label: t("partTime"), value: "PART_TIME" },
  ];
  const transportationNeedsOptions = [
    { label: t("noNeed"), value: "NO_NEED" },
    { label: t("homeToProvider"), value: "HOME_TO_PROVIDER" },
    { label: t("providerToHome"), value: "PROVIDER_TO_HOME" },
    {
      label: t("homeToProviderAndProviderToHome"),
      value: "HOME_TO_PROVIDER_AND_PROVIDER_TO_HOME",
    },
  ];
  const childCareOptions = [
    { label: t("familyChildCare"), value: "FAMILY_CHILD_CARE" },
    { label: t("notFamilyChildCare"), value: "NOT_FAMILY_CHILD_CARE" },
  ];
  const matches = useMediaQuery("(min-width:600px)");
  const { control, getValues, formState, setError } = useForm({
    defaultValues: {
      ...initialValues,
      children: initialValues?.children?.map((child) => String(child.id)) || [],
      voucher: initialValues?.voucher?.id || initialVoucherId,
    },
  });

  const { fieldWarningsAndErrors } = useFormWarnings({
    formState,
    fieldsWithWarnings,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: family } = useMyFamily();
  const childrenOptions = family?.children;
  const childOptions = (childrenOptions ?? []).map((child) => {
    let label = `${child.first_name} ${child.last_name}`;
    // Sometimes there's no known names for the child, so this makes the label go blank
    if (label == " ") {
      label = "Unknown Name";
    }
    return {
      label,
      value: child.id?.toString(),
    };
  });

  const possibleVouchers =
    initialValues?.voucher?.type === "WAITLIST"
      ? // This would include the waitlist voucher to be shown in the options, only because the select should be disabled
        // when initialValues is passed, because initialValues is passed only when editing an existing placement.
        // This option is added because some people may have created waitlist placements before we added the restriction
        // to not be able to create placements for waitlists
        family?.vouchers
      : family?.vouchers.filter((voucher) => voucher.type !== "WAITLIST");

  const voucherOptions = (possibleVouchers || []).map((voucher) => ({
    label: `${voucher.type} - ${dayjs(voucher.created_at).format("MM/DD/YYYY - h:mm a")}`,
    value: voucher.id,
  }));

  const handleSave = async () => {
    const values = getValues();
    setIsSubmitting(true);
    try {
      await onSave(values);
    } catch (errors: any) {
      errors.setFormErrors && errors.setFormErrors(setError);
      const errorMessage = extractErrorMessage(errors);
      toast.error(errorMessage);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form>
        <Stack spacing={2}>
          <Controller
            name="voucher"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("authorization")}
                options={voucherOptions}
                disabled={
                  Boolean(initialValues?.voucher) || Boolean(initialVoucherId)
                }
                fullWidth
                margin="normal"
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="children"
            control={control}
            render={({ field }) => (
              <POWERFULSelect
                multiple
                value={field.value || []}
                onChange={field.onChange}
                label={t("children")}
                options={childOptions}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="child_care_need"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childCareNeed")}
                options={childCareNeed}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="child_care_duration"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("childcareDuration")}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="preferred_program_type"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("preferredProgramType")}
                options={childCareOptions}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="child_care_transportation_needs"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childcareTransportationNeeds")}
                options={transportationNeedsOptions}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="provider_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("providerName")}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="provider_program"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("providerProgram")}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
        </Stack>
      </form>
      <Stack sx={{ marginTop: "30px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: "20px",
          }}
        >
          <Text
            variant="labelLarge"
            fontWeight={"600"}
            style={{ paddingRight: "10px" }}
          >
            {isMissingFields ? t("preferredSchedule") : t("weeklySchedule")}
          </Text>
          <Box
            sx={{ flexGrow: 1, height: "1px", bgcolor: colors.outlineVariant }}
          />
        </Box>
        <WeeklySchedule
          control={control}
          formState={formState}
          fieldsWithWarnings={fieldsWithWarnings}
        />
        {(formState.errors as any).non_field_errors && (
          <Text color="error">
            {(formState.errors as any).non_field_errors?.message}
          </Text>
        )}
      </Stack>
      {isMissingFields && (
        <Text
          variant="labelLarge"
          color={colors.onSurface}
          sx={{ margin: (theme) => theme.spacing(2, 0) }}
          component={"p"}
        >
          <Text fontWeight={"600"} component={"span"}>
            {t("important")}:
          </Text>
          <Text component={"span"}> {t("importantNote")}</Text>
        </Text>
      )}
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            margin: theme.spacing(4, 0),
          }}
        >
          <BasicButton
            label={t("back")}
            onClick={onCancel}
            isDisabled={isSubmitting}
          />
          <BasicButton
            label={t("save")}
            onClick={handleSave}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PlacementForm;
