import { useRef } from "react";
import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialogWithLanguage from "components/FullPageDialogWithLanguage";
import Stack from "components/Stack";
import Text from "components/Text";

import PrimaryContactVerifyInformationForm, {
  VerifyPrimaryInformationRef,
} from "entities/PrimaryContact/components/VerifyInformationForm";
import { useMyFamily } from "entities/Family/sdk";

import theme from "theme";
import dayjs from "dayjs";
import { incomeEligiblePrimaryContactUpdate } from "entities/PrimaryContact/sdk";
import { toast } from "react-toastify";
import { extractErrorMessage } from "utils/error";
import useIncomeEligibleFlowNavigation from "../hooks";

const ContactInfoPage = () => {
  const { t } = useTranslation();
  const formRef = useRef<VerifyPrimaryInformationRef>(null);
  const { data: family } = useMyFamily();
  const { navigateNext } = useIncomeEligibleFlowNavigation();

  if (!family) {
    return null;
  }

  const initialValues = {
    first_name: family.creator.first_name,
    last_name: family.creator.last_name,
    preferred_contact_method: family.creator.preferred_contact_method,
    preferred_language: family.creator.preferred_language,
    email: family.creator.email_contact_methods[0]?.email,
    phone_number: family.creator.phone_contact_methods[0]?.phone_number,
    date_of_birth: family.creator.date_of_birth,
  };

  const onSubmit = async (data: any) => {
    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : undefined;
    const primaryContactData = {
      ...data,
      date_of_birth: formattedBirthDate,
    };

    try {
      await incomeEligiblePrimaryContactUpdate({ data: primaryContactData });
      navigateNext({ family });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <FullPageDialogWithLanguage>
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack spacing={2} sx={{ height: "100%" }}>
          <Text variant="titleLarge">{t("confirmContactInfo")}</Text>
          <div style={{ flex: 1 }}>
            <PrimaryContactVerifyInformationForm
              ref={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
            />
          </div>
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasicButton
              label={t("next")}
              onClick={handleFormSubmit}
            ></BasicButton>
          </div>
        </Stack>
      </Container>
    </FullPageDialogWithLanguage>
  );
};

export default ContactInfoPage;
