import { IDocument, IFamilyDetail } from "entities/FamilySpecialist/sdk";

export const getDocuments = ({
  family,
  voucherId,
  childId,
}: {
  family: IFamilyDetail;
  voucherId: number;
  childId: number;
}) => {
  const addId = (documents: IDocument[]) =>
    documents.map((d) => ({ document_id: d.id, ...d }));

  const documents = addId(
    family.documents.filter(
      (d) =>
        d.vouchers.find((v) => v.id === voucherId) && d.child?.id === childId
    )
  );

  const citizenshipDocuments = documents.filter(
    (document) =>
      document.citizenship_verification ||
      document.parsing_types.includes("CHILD_CITIZENSHIP")
  );

  const ageDocuments = documents.filter(
    (document) =>
      document.age_verification || document.parsing_types.includes("CHILD_AGE")
  );
  const relationshipDocuments = documents.filter(
    (document) =>
      document.relationship_verification ||
      document.parsing_types.includes("CHILD_RELATIONSHIP")
  );

  return {
    documents,
    citizenshipDocuments,
    ageDocuments,
    relationshipDocuments,
  };
};
